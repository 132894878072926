import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { validateCache } from '@helpers';

import magentoService from '@services/magento.service';

import { actions as countryActions } from '@store/actions/share/country';
import { actions as dispatcherActions } from '@store/actions/share/dispatcher';
import { actions as allStatusLabelActions } from '@store/actions/share/status-label';
import { actions as storeActions } from '@store/actions/share/store';
import { actions as ticketConfigActions } from '@store/actions/share/ticket-config';
import { actions as usersActions } from '@store/actions/share/users';
import { ActionType } from '@store/interfaces/share';

interface AnyResponseInterface {
  [key: string]: any;
}

const getShareMetaData = (state: any) => state?.share?.metadata || {};

function* getAllStoreRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getAllStore.bind(magentoService));
    yield put(storeActions.getAllStoreSuccess(dataResponse));
  } catch (error: any) {
    yield put(storeActions.getAllStoreFailure(error?.message));
  }
}

function* getCountriesRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getCountries.bind(magentoService));
    yield put(countryActions.getCountriesSuccess(dataResponse));
  } catch (error: any) {
    yield put(countryActions.getCountriesFailure(error?.message));
  }
}

function* getUsersRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getUserList.bind(magentoService));
    yield put(usersActions.getUsersSuccess(dataResponse));
  } catch (error: any) {
    yield put(usersActions.getUsersFailure(error?.message));
  }
}

function* getTicketConfigRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getTicketConfig.bind(magentoService));
    yield put(ticketConfigActions.getTicketConfigSuccess(dataResponse));
  } catch (error: any) {
    yield put(ticketConfigActions.getTicketConfigFailure(error?.message));
  }
}

function* getDispatchersRequest(forceCache = true) {
  if (!forceCache) {
    const ttl = 30 * 24 * 60 * 60 * 1000;
    const expiredTime = new Date().getTime() - ttl;
    const state: AnyResponseInterface = yield select(getShareMetaData);
    if (validateCache(state.dispatchers, expiredTime)) {
      yield put(dispatcherActions.getDispatchersCached());
      return;
    }
  }
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getDispatchers.bind(magentoService));
    yield put(dispatcherActions.getDispatchersSuccess(dataResponse));
  } catch (error: any) {
    yield put(dispatcherActions.getDispatchersFailure(error?.message));
  }
}

function* getAllStatusLabel() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getManageStatusLabel.bind(magentoService));
    yield put(allStatusLabelActions.getAllStatusLabelSuccess(dataResponse));
  } catch (error: any) {
    yield put(allStatusLabelActions.getAllStatusLabelFailure(error?.message));
  }
}

function* getShareDataRequest() {
  yield delay(500);
  const state: AnyResponseInterface = yield select(getShareMetaData);
  // TODO: check cache and don't refetch
  const ttl = 30 * 24 * 60 * 60 * 1000;
  const expiredTime = new Date().getTime() - ttl;
  if (!validateCache(state.stores, expiredTime)) {
    yield put(storeActions.getAllStore());
  }
  if (!validateCache(state.users, expiredTime)) {
    yield put(usersActions.getUsers());
  }
  if (!validateCache(state.countries, expiredTime)) {
    yield put(countryActions.getCountries());
  }
  if (!validateCache(state.dispatchers, expiredTime)) {
    yield put(dispatcherActions.getDispatchers());
  }
  if (!validateCache(state.all_status_label, expiredTime)) {
    yield put(allStatusLabelActions.getAllStatusLabel());
  }
  if (!validateCache(state.ticketConfig, expiredTime)) {
    yield put(ticketConfigActions.getTicketConfig());
  }
}

function* invalidateCacheConfigRequest() {
  const state: AnyResponseInterface = yield select(getShareMetaData);
  const ttl = 5 * 60 * 1000; // 5min cache ttl
  const expiredTime = new Date().getTime() - ttl;
  if (!validateCache(state.ticketConfig, expiredTime)) {
    yield put(ticketConfigActions.getTicketConfig());
  }
}

function* shareDataRequestSaga() {
  yield takeLatest(ActionType.GET_SHARE_DATA_REQUEST, getShareDataRequest);
  yield takeLatest(ActionType.GET_ALL_STORE_REQUEST, getAllStoreRequest);
  yield takeLatest(ActionType.GET_COUNTRIES_REQUEST, getCountriesRequest);
  yield takeLatest(ActionType.GET_DISPATCHERS_REQUEST, getDispatchersRequest);
  yield takeLatest(ActionType.GET_ALL_STATUS_LABEL_REQUEST, getAllStatusLabel);
  yield takeLatest(ActionType.GET_USERS_REQUEST, getUsersRequest);
  yield takeLatest(ActionType.GET_TICKET_CONFIG_REQUEST, getTicketConfigRequest);
  yield takeLatest(ActionType.CACHE_INVALIDATE_TICKET_CONFIG, invalidateCacheConfigRequest);
}
export default shareDataRequestSaga;
