// eslint-disable-next-line
import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';
import { actions } from '@store/actions/shipments/shipping-service-detail';
import { Action, ActionType } from '@store/interfaces/shipments/shipping-service-detail';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getShippingServiceDetailRequest(payload: Action) {
  try {
    const { id } = payload;
    const dataResponse: AnyResponseInterface = yield call(magentoService.fetchShippingServiceDetail.bind(magentoService), String(id));
    yield put(actions.getShippingServiceDetailSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardOrdersError());
  } catch (error: any) {
    yield put(actions.getShippingServiceDetailFailure(error?.message));
  }
}

function* shippingServiceDetailSaga() {
  yield takeLatest(ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST, getShippingServiceDetailRequest);
}
export default shippingServiceDetailSaga;
