import { Action, ActionType } from '@store/interfaces/returns/request-item';

const getReturnsRequestItem = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_REQUEST, payload };
};
const getReturnsRequestItemSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_REQUEST_SUCCESS, payload };
};
const getReturnsRequestItemFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_REQUEST_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getReturnsRequestItem,
  getReturnsRequestItemSuccess,
  getReturnsRequestItemFailure,

  clearMessageError,
};

const returns = {
  actions,
};

export default returns;
