import { combineReducers } from 'redux';

import users from './users';

const usersReducer = combineReducers({
  users,
  //
});

export default usersReducer;
