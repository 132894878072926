import React from 'react';
import { NavLink } from 'react-router-dom';

import qs from 'qs';

import useHasPermission from '@hooks/useHasPermission';

export interface SubNavItemInterface {
  label: string;
  to?: string;
  query?: any;
  className?: string;
  textClass?: string;
  permission?: string;
}

function SubNavItem(props: SubNavItemInterface) {
  const { label, to, query, className, textClass, permission } = props;
  const hasPermission = useHasPermission(String(permission || ''));
  const queryParam = qs.stringify(query || {});
  const url = `${to}${queryParam ? '?' : ''}${queryParam}`;
  if (!hasPermission) {
    return <></>;
  }
  return (
    <li className={`pb-2 transition-opacity duration-150 hover:opacity-75 ${className || ''}`}>
      <NavLink className={`text-normal ${textClass || ''}`} to={url}>
        {label}
      </NavLink>
    </li>
  );
}

export default SubNavItem;
