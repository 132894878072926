import React from 'react';

import SubNavItem, { SubNavItemInterface } from './SubNavItem';
export interface SubNavInterface {
  items: SubNavItemInterface[];
}

function SubNav(props: SubNavInterface) {
  const { items } = props;
  return (
    <ul className="text-gray-300 child-menu z-10 pl-[53px]">
      {items.map((item, idx) => {
        return <SubNavItem key={idx} {...item} />;
      })}
    </ul>
  );
}

export default SubNav;
