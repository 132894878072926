import { Action, ActionType } from '@store/interfaces/user/users';

const getUsers = (payload: any): Action => {
  return { type: ActionType.GET_ITEMS_REQUEST, payload };
};
const getUsersSuccess = (payload: any): Action => {
  return { type: ActionType.GET_ITEMS_REQUEST_SUCCESS, payload };
};
const getUsersFailure = (error?: any): Action => {
  return { type: ActionType.GET_ITEMS_REQUEST_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getUsers,
  getUsersSuccess,
  getUsersFailure,

  clearMessageError,
};

const users = {
  actions,
};

export default users;
