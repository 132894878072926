import React, { useEffect, useRef } from 'react';

interface CheckBoxProps {
  divParentClassName?: string;
  inputClassName?: string;
  spanClassName?: string;
  indeterminate?: boolean;
  title?: string;
  name: string;
}

export const CheckBoxRef: React.ForwardRefRenderFunction<HTMLInputElement, CheckBoxProps & React.InputHTMLAttributes<HTMLInputElement>> = (
  { divParentClassName, inputClassName, spanClassName, title, indeterminate, ...props },
  forwardedRef,
) => {
  const innerRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.indeterminate = !!indeterminate;
    }
  }, [indeterminate]);
  return (
    <div className={divParentClassName || 'flex items-center gap-x-[11px]'}>
      <input
        className={inputClassName || 'checkbox checkbox-primary rounded border-2 w-[18px] h-[18px]'}
        type="checkbox"
        {...props}
        ref={(instance: HTMLInputElement) => {
          innerRef.current = instance;
          if (typeof forwardedRef === 'function') {
            forwardedRef(instance);
          } else if (forwardedRef !== null) {
            forwardedRef.current = instance;
          }
        }}
      />
      {title && <span className={spanClassName || 'text-gray-500 leading-4 dark:text-gray-dark-500 text-[14px] max-w-[183px]'}>{title}</span>}
    </div>
  );
};

export default React.forwardRef(CheckBoxRef);
