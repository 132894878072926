export enum ActionType {
  GET_PRODUCTION_STATS_REQUEST = `reports/late-item-stats/GET_PRODUCTION_STATS_REQUEST`,
  GET_PRODUCTION_STATS_SUCCESS = `reports/late-item-stats/GET_PRODUCTION_STATS_SUCCESS`,
  GET_PRODUCTION_STATS_FAILURE = `reports/late-item-stats/GET_PRODUCTION_STATS_FAILURE`,

  GET_SHIPMENT_STATS_REQUEST = `reports/late-item-stats/GET_SHIPMENT_STATS_REQUEST`,
  GET_SHIPMENT_STATS_SUCCESS = `reports/late-item-stats/GET_SHIPMENT_STATS_SUCCESS`,
  GET_SHIPMENT_STATS_FAILURE = `reports/late-item-stats/GET_SHIPMENT_STATS_FAILURE`,

  GET_RETURN_STATS_REQUEST = `reports/late-item-stats/GET_RETURN_STATS_REQUEST`,
  GET_RETURN_STATS_SUCCESS = `reports/late-item-stats/GET_RETURN_STATS_SUCCESS`,
  GET_RETURN_STATS_FAILURE = `reports/late-item-stats/GET_RETURN_STATS_FAILURE`,

  CLEAR_MESSAGE_ERROR = `reports/late-item-stats/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `reports/late-item-stats/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
