// eslint-disable-next-line
import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';
import { actions } from '@store/actions/shipments/pull-tracking-info';
import { Action, ActionType } from '@store/interfaces/shipments/pull-tracking-info';

interface AnyResponseInterface {
  [key: string]: any;
}

function* pullTrackingInfoRequest(payload: Action) {
  try {
    const { id } = payload;
    const dataResponse: AnyResponseInterface = yield call(magentoService.pullShipmentInfoFromCarrier.bind(magentoService), String(id));
    yield put(actions.pullTrackingInfoSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.pullTrackingInfoFailure(error?.message));
  }
}

function* pullTrackingInfoSaga() {
  yield takeLatest(ActionType.PULL_TRACKING_INFO_REQUEST, pullTrackingInfoRequest);
}
export default pullTrackingInfoSaga;
