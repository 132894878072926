import React, { useCallback } from 'react';

import { twMerge } from 'tailwind-merge';

import { Icon } from '@components/base';

import { IColumnType } from './Table';

interface TableHeadProps {
  className?: string;
  cellClass?: string;
  borderClass?: string;
  setSorting?: (idx: string, sorting: 'asc' | 'desc' | any) => void;
  columns: IColumnType[];
}

const SortContainer: React.FC<any> = ({ children, setSorting, sortable, sorting }) => {
  const toggleSorting = useCallback(
    (newSort: string) => {
      if (newSort === sorting) {
        setSorting('');
      } else {
        setSorting(newSort);
      }
    },
    [setSorting, sorting],
  );
  return (
    <span className="flex flex-row items-center">
      {children}
      {sortable && setSorting && (
        <span className="flex flex-col ml-2">
          <span className="cursor-pointer" onClick={() => toggleSorting('desc')}>
            <Icon name="arrow-up-black" className={sorting?.toLowerCase() === 'desc' ? '' : 'opacity-25'} alt="arrow up icon" />
          </span>
          <span className="cursor-pointer" onClick={() => toggleSorting('asc')}>
            <Icon name="arrow-down-black" className={sorting?.toLowerCase() === 'asc' ? '' : 'opacity-25'} alt="arrow down icon" />
          </span>
        </span>
      )}
    </span>
  );
};

export const TableHead: React.FC<TableHeadProps> = ({ setSorting, borderClass, cellClass, className, columns }) => {
  return (
    <tr className={className || ''}>
      {columns.map((column, columnIndex) => {
        const Component = column.headComponent;
        const columnId = column.key;
        const classes = twMerge(cellClass, column.headClass || '', borderClass);
        return (
          <th className={classes} key={`table-head-cell-${columnIndex}`}>
            {Component ? (
              <Component label={column.title} headerOptions={column} setSorting={setSorting} />
            ) : (
              <SortContainer
                sortable={column.sortable && setSorting}
                sorting={column.sorting}
                setSorting={(sortOrder: any) => setSorting && setSorting(columnId, sortOrder)}
              >
                {column.title}
              </SortContainer>
            )}
          </th>
        );
      })}
    </tr>
  );
};

export default TableHead;
