import React, { useCallback, useRef, useState } from 'react';

import { copyRichText, copyText } from '@helpers/dom';

import { Icon } from '../Icon';

interface CopyBoxInterface {
  type: 'text' | 'html' | 'both';
  textCopy?: string;
}

export const CopyBox: React.FC<React.PropsWithChildren<CopyBoxInterface & HTMLDivElement> & any> = ({
  textCopy = undefined,
  type = 'both',
  children,
  ..._props
}) => {
  const [success, setSuccess] = useState(false);
  const copyRef = useRef<HTMLDivElement>(null);
  const onCopy = useCallback(() => {
    if (typeof textCopy === 'string') {
      copyText(textCopy);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 1500);
      return;
    }
    if (!copyRef.current) {
      return;
    }
    if (type === 'text') {
      copyText(copyRef.current.textContent || '');
      setSuccess(true);
      setTimeout(() => setSuccess(false), 1500);
    } else {
      copyRichText(copyRef.current.innerHTML.trim(), (copyRef.current.textContent || '').trim(), () => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 1500);
      });
    }
  }, [type, textCopy]);
  if (children === undefined && typeof textCopy === 'undefined') {
    return <></>;
  }
  return (
    <div className="flex center-item box-copy-container">
      <div className="box-copy-content" ref={copyRef}>
        {children ? children : <>{textCopy}</>}
      </div>
      <button className={`tooltip box-copy-action ${success ? 'has-copied' : ''}`} onClick={onCopy} data-tip={`${success ? 'Copied' : 'Copy'}`}>
        <Icon name="copy" className="filter-gray" />
      </button>
    </div>
  );
};

export default CopyBox;
