import { Action, ActionType } from '@store/interfaces/share';

const getAllStatusLabel = (): Action => {
  return { type: ActionType.GET_ALL_STATUS_LABEL_REQUEST };
};
const getAllStatusLabelSuccess = (payload: any): Action => {
  return { type: ActionType.GET_ALL_STATUS_LABEL_SUCCESS, payload };
};
const getAllStatusLabelFailure = (error?: any): Action => {
  return { type: ActionType.GET_ALL_STATUS_LABEL_FAILURE, error };
};

export const actions = {
  getAllStatusLabel,
  getAllStatusLabelSuccess,
  getAllStatusLabelFailure,
};

const allStatusLabel = {
  actions,
};

export default allStatusLabel;
