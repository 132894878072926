import React from 'react';

import { twMerge } from 'tailwind-merge';

import Select, { TextAreaInterface } from '../TextArea';

export interface TextAreaFieldInterface extends TextAreaInterface {
  label?: string | React.ReactNode;
  labelClassName?: string;
  error?: string | React.ReactNode;
  errorClassName?: string;
  containerClassName?: string;
}

const TextAreaField: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaFieldInterface & React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ label, error, labelClassName, errorClassName, containerClassName, ...props }, ref) => {
  const { size } = props;
  const textSize = size ? `text-${size}` : '';
  const labelClasses = twMerge('label-text font-medium', textSize, labelClassName || '');
  const errorClasses = twMerge('mt-1 text-red italic text-sm', textSize, errorClassName || '');
  const containerClasses = twMerge('form-control', containerClassName || '');
  return (
    <div className={containerClasses}>
      {label && (
        <label className="label">
          <span className={labelClasses}>{label}</span>
        </label>
      )}
      <Select {...props} ref={ref} />
      {error && <p className={errorClasses}>{error}</p>}
    </div>
  );
};

export default React.forwardRef(TextAreaField);
