export enum ActionType {
  GET_ITEMS_REQUEST = `reports/latest-order/GET_ITEMS_REQUEST`,
  GET_ITEMS_REQUEST_SUCCESS = `reports/latest-order/GET_ITEMS_REQUEST_SUCCESS`,
  GET_ITEMS_REQUEST_FAILURE = `reports/latest-order/GET_ITEMS_REQUEST_FAILURE`,

  GET_SHARE_DATE_LATE_GRID = `reports/latest-order/GET_SHARE_DATE_LATE_GRID`,

  CLEAR_MESSAGE_ERROR = `reports/latest-order/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `reports/latest-order/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
