import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { DISPATCH_TYPE, ObjectInterface } from '@interfaces';

import { PUBLIC_URL } from '@constants';

import { actions } from '@store/actions/share/current-dispatcher';
import { ActionType } from '@store/interfaces/auth';
import { ActionType as ShareActionType } from '@store/interfaces/share';

import { Button, Icon } from '@components/base';

const defaultWarehouse = { label: 'All Warehouse', value: '' };

const UserDropdown: React.FC<any> = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth);
  const handleLogout = () => dispatch({ type: ActionType.LOGOUT });

  if (!user || !user.loggedIn) {
    return <div className="flex items-center order-2 user-noti gap-[30px] xl:gap-[48px] lg:order-3 lg:mr-0"></div>;
  }

  return (
    <div className="flex items-center order-2 user-noti gap-[30px] xl:gap-[48px] lg:order-3 lg:mr-0">
      <div className="dropdown dropdown-end">
        <label className="cursor-pointer dropdown-label">
          <a href="#">
            <img src={`${PUBLIC_URL}/static/assets/images/avatar-layouts-5.png`} alt="user avatar" />
          </a>
        </label>
        <ul className="dropdown-content">
          <div className="relative menu rounded-box dropdown-shadow p-[25px] pb-[10px] bg-neutral-bg mt-[25px] md:mt-[40px] min-w-[237px] dark:text-gray-dark-500 dark:border-dark-neutral-border dark:bg-dark-neutral-bg">
            <div className="border-solid border-b-8 border-x-transparent border-x-8 border-t-0 absolute w-[14px] top-[-7px] border-b-neutral-bg dark:border-b-dark-neutral-bg right-[18px]"></div>
            <li className="text-gray-500 hover:text-gray-1100 hover:bg-gray-100 dark:text-gray-dark-500 dark:hover:text-gray-dark-1100 dark:hover:bg-gray-dark-100 rounded-lg group p-[15px] pl-[21px]">
              <Link className="flex items-center bg-transparent p-0 gap-[7px]" to="/dashboard">
                <i className="w-4 h-4 grid place-items-center">
                  <Icon className="group-hover:filter-black dark:group-hover:filter-white" name="favorite-chart" alt="icon" />
                </i>
                <span>Dashboard</span>
              </Link>
            </li>
            <div className="w-full bg-neutral h-[1px] my-[7px] dark:bg-dark-neutral-border"></div>
            <li className="text-gray-500 hover:text-gray-1100 hover:bg-gray-100 dark:text-gray-dark-500 dark:hover:text-gray-dark-1100 dark:hover:bg-gray-dark-100 rounded-lg group p-[15px] pl-[21px]">
              <a className="flex items-center bg-transparent p-0 gap-[7px]" href="#" onClick={handleLogout}>
                <i className="w-4 h-4 grid place-items-center">
                  <Icon className="group-hover:filter-black dark:group-hover:filter-white" name="logout" alt="icon" />
                </i>
                <span>Log out</span>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

const Header: React.FC<any> = ({ setIsMobileMenuOpen }) => {
  const dispatch = useDispatch();
  const dispatchers = useSelector((state: any) => state?.share?.dispatchers || []);
  const dispatcher = useSelector((state: any) => state?.share?.currentDispatcher);

  useEffect(() => {
    dispatch({ type: ShareActionType.GET_SHARE_DATA_REQUEST });
  }, [dispatch]);
  const dispatcherOptions = useMemo(() => {
    if (!dispatchers.reduce) {
      return [];
    }
    const optGroup: ObjectInterface = {
      [DISPATCH_TYPE.SHIPOUT]: {
        label: 'Shipout',
        options: [],
      },
      [DISPATCH_TYPE.RETURN_SHIPOUT]: {
        label: 'Return Shipout',
        options: [],
      },
    };

    dispatchers.forEach(({ location_id, dispatch_type, dispatch_location_name }: any) => {
      if (optGroup[+dispatch_type]) {
        optGroup[+dispatch_type].options.push({
          label: dispatch_location_name,
          value: location_id,
        });
      }
    });
    return [
      { ...defaultWarehouse },
      ...Object.keys(optGroup).reduce((arr: any, name) => {
        arr.push(optGroup[name]);
        return arr;
      }, []),
    ];
  }, [dispatchers]);

  const dispatcherMapping = useMemo(() => {
    const res: ObjectInterface = {};
    if (Array.isArray(dispatchers)) {
      Array.from(dispatchers).forEach((dispatch) => {
        const { location_id } = dispatch;
        res[location_id] = dispatch;
      });
    }
    return res;
  }, [dispatchers]);

  const [warehouse, setWarehouse] = useState<any>(
    dispatcher ? { label: dispatcher?.dispatch_location_name, value: dispatcher?.location_id } : { ...defaultWarehouse },
  );
  const handleChangeWarehouse = useCallback(
    (warehouse: any) => {
      const { value: locationId } = warehouse;
      setWarehouse(warehouse);
      const dispatcherLocation: any = dispatcherMapping[locationId] || null;
      dispatch(actions.setCurrentDispatcher(dispatcherLocation));
    },
    [dispatch, dispatcherMapping],
  );
  const toggleMenu = useCallback(() => {
    setIsMobileMenuOpen((active: boolean) => !active);
  }, [setIsMobileMenuOpen]);

  return (
    <header className="flex items-center justify-between bg-neutral-bg p-5 gap-5 md:py-6 md:pl-[25px] md:pr-[38px] lg:flex-nowrap dark:bg-dark-neutral-bg lg:gap-0">
      <Button className="border-none md:hidden hover:bg-transparent hover:border-1 p-1" onClick={toggleMenu}>
        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </Button>
      <span className="hidden md:inline"></span>
      <div className="form-control min-w-[60%] md:min-w-[300px]">
        <Select className="w-full" isSearchable={true} value={warehouse} onChange={handleChangeWarehouse} options={dispatcherOptions} />
      </div>
      <UserDropdown />
    </header>
  );
};

export default Header;
