export enum ActionType {
  GET_ITEMS_REQUEST = `reports/shipment-overdue-delivery/GET_ITEMS_REQUEST`,
  GET_ITEMS_REQUEST_SUCCESS = `reports/shipment-overdue-delivery/GET_ITEMS_REQUEST_SUCCESS`,
  GET_ITEMS_REQUEST_FAILURE = `reports/shipment-overdue-delivery/GET_ITEMS_REQUEST_FAILURE`,

  CLEAR_MESSAGE_ERROR = `reports/shipment-overdue-delivery/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `reports/shipment-overdue-delivery/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
