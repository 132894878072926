import { Action, ActionType } from '@store/interfaces/shipments/shipping-service-detail';

const getShippingServiceDetail = (id: string | number, force = false): Action => {
  return { type: ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST, id, force };
};
const getShippingServiceDetailSuccess = (payload: any): Action => {
  return { type: ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST_SUCCESS, payload };
};
const getShippingServiceDetailFailure = (error?: any): Action => {
  return { type: ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST_FAILURE, error };
};
const clearShippingServiceDetailState = (): Action => {
  return { type: ActionType.CLEAR_SHIPPING_SERVICE_DETAIL_REQUEST_STATE };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getShippingServiceDetail,
  getShippingServiceDetailSuccess,
  getShippingServiceDetailFailure,
  clearShippingServiceDetailState,
  clearMessageError,
};

const shippingServiceDetail = {
  actions,
};

export default shippingServiceDetail;
