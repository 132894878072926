import React, { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface ButtonInterface {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  label?: string;
  variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'danger';
  backgroundColor?: string;
  onClick?: React.MouseEventHandler;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  [key: string]: any;
}

const getSizeClasses = (size: any) => {
  switch (size) {
    case 'xs': {
      return 'btn-xs';
    }
    case 'sm': {
      return 'btn-sm';
    }
    case 'lg': {
      return 'btn-lg';
    }
    case 'xl': {
      return 'btn-xl';
    }
    default: {
      return '';
    }
  }
};

const getModeClasses = (variant: string) => {
  const classes: any = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    success: 'btn-success',
    warning: 'warning',
    info: 'btn-info',
    danger: 'btn-danger',
  };
  return classes[variant] || '';
};

const BASE_BUTTON_CLASSES = 'btn normal-case transition-all duration-300 rounded inline-block';

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<React.PropsWithChildren<ButtonInterface>> = ({
  variant = 'default',
  backgroundColor,
  size = 'md',
  label = '',
  children,
  className = '',
  type = 'button',
  ...props
}) => {
  const computedClasses = useMemo(() => {
    const modeClass = getModeClasses(variant);
    const sizeClass = getSizeClasses(size);

    return [modeClass, sizeClass].join(' ');
  }, [variant, size]);
  const classes = twMerge(BASE_BUTTON_CLASSES, computedClasses, className);
  return (
    <button type={type} className={classes} style={{ backgroundColor }} {...props}>
      {children} {label}
    </button>
  );
};

export default Button;
