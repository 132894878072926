import { call, cancelled, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

import { ObjectInterface } from '@interfaces';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/report/shipment-overdue-delivery';
import { Action, ActionType } from '@store/interfaces/report/shipment-overdue-delivery';

interface AnyResponseInterface {
  [key: string]: any;
}
// const takeLatest2 = (patternOrChannel: string, saga: any, ...args: any[]) =>
//   fork(function* (): any {
//     let lastTask: Task<any> | null = null;
//     while (true) {
//       const action = yield take(patternOrChannel);
//       if (lastTask) {
//         yield cancel(lastTask); // cancel is no-op if the task has already terminated
//       }
//       // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
//       lastTask = yield fork(saga, ...args.concat(action));
//     }
//   });

function* request({ payload }: Action): any {
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const params: ObjectInterface = { ...(payload || {}) };
    const options: ObjectInterface = { cancelToken: cancelTokenSource.token };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getShipmentOverdueDelivery.bind(magentoService), params, options);
    yield put(actions.getItemsSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getItemsFailure(error?.message));
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      cancelTokenSource.cancel();
    }
  }
}

function* requestSaga() {
  yield takeLatest(ActionType.GET_ITEMS_REQUEST, request);
}
export default requestSaga;
