import React from 'react';

import Links from '@components/base/Links/Links';
import Copyright from '@components/Copyright/Copyright';

function Footer(): React.ReactElement<HTMLElement> {
  return (
    <footer className="mt-[37px]">
      <div className="w-full bg-neutral h-[1px] dark:bg-dark-neutral-border mb-[25px]"></div>
      <div className="flex items-center justify-between text-desc text-gray-400 flex-wrap gap-5 dark:text-gray-dark-400">
        <Copyright />
        <Links />
      </div>
    </footer>
  );
}

export default Footer;
