import React from 'react';
import { RouterProvider } from 'react-router-dom';

import PageTitleContext from '@layouts/context/PageTitleContext';

import ThemeContext from './themes/ThemeContext';

import { router } from '~/routes';

function App() {
  return (
    <ThemeContext>
      <PageTitleContext>
        <RouterProvider router={router} />
      </PageTitleContext>
    </ThemeContext>
  );
}

export default App;
