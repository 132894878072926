// https://gist.github.com/codeguy/6684588?permalink_comment_id=4325476#gistcomment-4325476
export function slugify(text: string) {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\_/g, '-') // Replace _ with -
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/\-$/g, ''); // Remove trailing -
}

export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export const escapeRegExp = (str: string) =>
  str
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&') // $& means the whole matched string
    .replace(/-/g, '\\x2d');

export function template(template: string, placeholders: { [key: string]: any }, empty = '') {
  const keys = Object.keys(placeholders);
  if (!keys.length) {
    return template;
  }
  const regex = new RegExp('(' + keys.map((key: string) => escapeRegExp(`{{${key}}}`)).join('|') + ')', 'g');
  return template.replace(regex, (_, match: string) => {
    const key = trim(match, '{}');
    if (placeholders[key] === undefined || placeholders[key] === null) {
      return empty;
    }
    return placeholders[key]?.toString();
  });
}

export function rtrim(str: string, c?: string) {
  if (!str) {
    return str;
  }
  if (c == undefined) {
    c = '\\s';
  } else {
    c = escapeRegExp(c);
  }

  return str.replace(new RegExp('[' + c + ']*$'), '');
}

export function ltrim(str: string, c?: string) {
  if (!str) {
    return str;
  }
  if (c == undefined) {
    c = '\\s';
  } else {
    c = escapeRegExp(c);
  }

  return str.replace(new RegExp('^[' + c + ']*'), '');
}

export function trim(str: string, c?: string) {
  return rtrim(ltrim(str, c), c);
}
