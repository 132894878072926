import { useSelector } from 'react-redux';

import { ObjectInterface } from './../interfaces/global';

export const usePermissions = (): ObjectInterface => {
  const permissions = useSelector((state: any) => state.auth?.user?.permissions || {});
  return permissions;
};

export default usePermissions;
