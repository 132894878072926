import { Action, ActionType } from '@store/interfaces/auth';

const requestToken = (code: string): Action => {
  return { type: ActionType.TOKEN_REQUEST, code };
};
const requestTokenSuccess = (): Action => {
  return { type: ActionType.TOKEN_REQUEST_SUCCESS };
};
const requestTokenFail = (): Action => {
  return { type: ActionType.TOKEN_REQUEST_FAILURE };
};
const loginRequest = (username: string, password: string): Action => {
  return { type: ActionType.LOGIN_REQUEST, payload: { username, password } };
};
const loginSuccess = (data: any): Action => {
  return { type: ActionType.LOGIN_SUCCESS, payload: data };
};
const loginFailure = (error: string | any): Action => {
  return { type: ActionType.LOGIN_FAILURE, error };
};

const updateToken = (payload: { refreshToken?: string; accessToken?: string; [key: string]: any }) => {
  return { type: ActionType.UPDATE_TOKEN, payload };
};

const clearLoginState = (): Action => {
  return { type: ActionType.CLEAR_LOGIN_STATE };
};

const userInfoRequest = (payload: any, isLogging = false): Action => {
  return { type: ActionType.USER_INFO_REQUEST, payload: { ...payload, isLogging } };
};

const userInfoSuccess = (payload: any): Action => {
  return { type: ActionType.USER_INFO_SUCCESS, payload: { ...payload } };
};

const logout = (): Action => {
  return { type: ActionType.LOGOUT };
};

export const actions = {
  requestToken,
  requestTokenSuccess,
  requestTokenFail,
  loginRequest,
  userInfoRequest,
  userInfoSuccess,
  loginSuccess,
  loginFailure,
  updateToken,
  clearLoginState,
  logout,
};

const auth = {
  actions,
};

export default auth;
