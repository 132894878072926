import { Action, ActionType } from '@store/interfaces/returns/dashboard';

const getReturnsDashboard = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_REQUEST, payload };
};
const getReturnsDashboardSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_SUCCESS, payload };
};
const getReturnsDashboardFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_FAILURE, error };
};

const getReturnsDashboardOrders = (payload?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_ORDERS_REQUEST, payload };
};
const getReturnsDashboardOrdersSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_ORDERS_SUCCESS, payload };
};
const getReturnsDashboardOrdersFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_ORDERS_FAILURE, error };
};
const clearShipmentDashboardOrdersError = (): Action => {
  return { type: ActionType.CLEAR_RETURNS_DASHBOARD_ORDERS_MESSAGE_ERROR };
};

const getReturnsDashboardServiceWarranty = (payload?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_REQUEST, payload };
};
const getReturnsDashboardServiceWarrantySuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_SUCCESS, payload };
};
const getReturnsDashboardServiceWarrantyFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_FAILURE, error };
};
const clearShipmentDashboardServiceWarrantyError = (): Action => {
  return { type: ActionType.CLEAR_RETURNS_DASHBOARD_SERVICE_WARRANTY_MESSAGE_ERROR };
};

const getReturnsDashboardDispatcher = (payload?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_REQUEST, payload };
};
const getReturnsDashboardDispatcherSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_SUCCESS, payload };
};
const getReturnsDashboardDispatcherFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_FAILURE, error };
};
const clearShipmentDashboardDispatcherError = (): Action => {
  return { type: ActionType.CLEAR_RETURNS_DASHBOARD_DISPATCHER_MESSAGE_ERROR };
};

const getReturnsDashboardFinanceTransaction = (payload?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_REQUEST, payload };
};
const getReturnsDashboardFinanceTransactionSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_SUCCESS, payload };
};
const getReturnsDashboardFinanceTransactionFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_FAILURE, error };
};
const clearShipmentDashboardFinanceTransactionError = (): Action => {
  return { type: ActionType.CLEAR_RETURNS_DASHBOARD_FINANCE_TRANSACTION_MESSAGE_ERROR };
};

const getReturnsDashboardReturnJourney = (payload?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_REQUEST, payload };
};
const getReturnsDashboardReturnJourneySuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_SUCCESS, payload };
};
const getReturnsDashboardReturnJourneyFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_FAILURE, error };
};
const clearShipmentDashboardReturnJourneyError = (): Action => {
  return { type: ActionType.CLEAR_RETURNS_DASHBOARD_RETURN_JOURNEY_MESSAGE_ERROR };
};

const getReturnsDashboardRepairJourney = (payload?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_REQUEST, payload };
};
const getReturnsDashboardRepairJourneySuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_SUCCESS, payload };
};
const getReturnsDashboardRepairJourneyFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_FAILURE, error };
};
const clearShipmentDashboardRepairJourneyError = (): Action => {
  return { type: ActionType.CLEAR_RETURNS_DASHBOARD_REPAIR_JOURNEY_MESSAGE_ERROR };
};
const clearShipmentDashboardState = (): Action => {
  return { type: ActionType.CLEAR_STATE };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getReturnsDashboard,
  getReturnsDashboardSuccess,
  getReturnsDashboardFailure,

  getReturnsDashboardOrders,
  getReturnsDashboardOrdersSuccess,
  getReturnsDashboardOrdersFailure,
  clearShipmentDashboardOrdersError,

  getReturnsDashboardServiceWarranty,
  getReturnsDashboardServiceWarrantySuccess,
  getReturnsDashboardServiceWarrantyFailure,
  clearShipmentDashboardServiceWarrantyError,

  getReturnsDashboardDispatcher,
  getReturnsDashboardDispatcherSuccess,
  getReturnsDashboardDispatcherFailure,
  clearShipmentDashboardDispatcherError,

  getReturnsDashboardFinanceTransaction,
  getReturnsDashboardFinanceTransactionSuccess,
  getReturnsDashboardFinanceTransactionFailure,
  clearShipmentDashboardFinanceTransactionError,

  getReturnsDashboardReturnJourney,
  getReturnsDashboardReturnJourneySuccess,
  getReturnsDashboardReturnJourneyFailure,
  clearShipmentDashboardReturnJourneyError,

  getReturnsDashboardRepairJourney,
  getReturnsDashboardRepairJourneySuccess,
  getReturnsDashboardRepairJourneyFailure,
  clearShipmentDashboardRepairJourneyError,

  clearMessageError,
  clearShipmentDashboardState,
};

const returns = {
  actions,
};

export default returns;
