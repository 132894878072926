import { all } from 'redux-saga/effects';

import dashboardSaga from './dashboard';
import dashboardDetailSaga from './dashboard-detail';
import requestItemSaga from './request-item';
import shareSaga from './share';

function* returns(): any {
  yield all([
    shareSaga(),
    dashboardSaga(),
    dashboardDetailSaga(),
    requestItemSaga(),
    // other watch
  ]);
}

export default returns;
