import { Action, ActionType, AsyncAction, AsyncActionType } from '@store/interfaces/counter';

const increment = (): Action => {
  return { type: ActionType.increment };
};
const decrement = (): Action => {
  return { type: ActionType.decrement };
};
const incrementStarted = (): AsyncAction => {
  return { type: AsyncActionType.incrementStarted };
};
const incrementDone = (): AsyncAction => {
  return { type: AsyncActionType.incrementDone };
};
const incrementFailed = (): AsyncAction => {
  return { type: AsyncActionType.incrementFailed };
};
const decrementStarted = (): AsyncAction => {
  return { type: AsyncActionType.decrementStarted };
};
const decrementDone = (): AsyncAction => {
  return { type: AsyncActionType.decrementDone };
};
const decrementFailed = (): AsyncAction => {
  return { type: AsyncActionType.decrementFailed };
};

export const actions = { increment, decrement };
export const asyncActions = {
  incrementStarted,
  incrementDone,
  incrementFailed,
  decrementStarted,
  decrementDone,
  decrementFailed,
};
const counter = {
  actions,
  asyncActions,
};
export default counter;
