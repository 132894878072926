import { AsyncActionType, CounterState } from '@store/interfaces/counter';

const initialState: CounterState = {
  isLoading: false,
  errorMessage: '',
  count: 0,
};

export default function counter(state = initialState, action: any) {
  switch (action.type) {
    case AsyncActionType.incrementStarted:
    case AsyncActionType.decrementStarted:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case AsyncActionType.incrementFailed:
    case AsyncActionType.decrementFailed:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Request failed',
      };
    case AsyncActionType.incrementDone:
      return {
        ...state,
        isLoading: false,
        count: state.count + 1,
      };
    case AsyncActionType.decrementDone:
      return {
        ...state,
        isLoading: false,
        count: state.count - 1,
      };
    default:
      return state;
  }
}
