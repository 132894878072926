import { Action, ActionType } from '@store/interfaces/shipments/dashboard';

const getPreShipmentDashboard = (payload?: any): Action => {
  return { type: ActionType.GET_PRE_SHIPMENT_DASHBOARD_REQUEST, payload };
};
const getPreShipmentDashboardSuccess = (payload: any): Action => {
  return { type: ActionType.GET_PRE_SHIPMENT_DASHBOARD_SUCCESS, payload };
};
const getPreShipmentDashboardFailure = (error?: any): Action => {
  return { type: ActionType.GET_PRE_SHIPMENT_DASHBOARD_FAILURE, error };
};
const clearPreShipmentDashboardState = (): Action => {
  return { type: ActionType.CLEAR_PRE_SHIPMENT_DASHBOARD_STATE };
};
const clearMessagePreShipmentDashboardError = (error?: any): Action => {
  return { type: ActionType.GET_PRE_SHIPMENT_DASHBOARD_FAILURE, error };
};

const getDispatcherDashboard = (payload?: any): Action => {
  return { type: ActionType.GET_DISPATCHER_DASHBOARD_REQUEST, payload };
};

const getDispatcherDashboardSuccess = (payload: any): Action => {
  return { type: ActionType.GET_DISPATCHER_DASHBOARD_SUCCESS, payload };
};

const getDispatcherDashboardFailure = (error?: any): Action => {
  return { type: ActionType.GET_DISPATCHER_DASHBOARD_FAILURE, error };
};

const clearDispatcherDashboardState = (): Action => {
  return { type: ActionType.CLEAR_PRE_SHIPMENT_DASHBOARD_STATE };
};

const clearMessageDispatcherDashboardError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_DISPATCHER_DASHBOARD_ERROR };
};

const clearShipmentDashboardState = (): Action => {
  return { type: ActionType.CLEAR_STATE };
};

export const actions = {
  getPreShipmentDashboard,
  getPreShipmentDashboardSuccess,
  getPreShipmentDashboardFailure,
  clearPreShipmentDashboardState,
  clearMessagePreShipmentDashboardError,

  getDispatcherDashboard,
  getDispatcherDashboardSuccess,
  getDispatcherDashboardFailure,
  clearDispatcherDashboardState,
  clearMessageDispatcherDashboardError,

  clearShipmentDashboardState,
};

const shipments = {
  actions,
};

export default shipments;
