export enum ActionType {
  GET_PRE_SHIPMENT_DASHBOARD_REQUEST = `dashboard/shipments/GET_PRE_SHIPMENT_DASHBOARD_REQUEST`,
  GET_PRE_SHIPMENT_DASHBOARD_SUCCESS = `dashboard/shipments/GET_PRE_SHIPMENT_DASHBOARD_SUCCESS`,
  GET_PRE_SHIPMENT_DASHBOARD_FAILURE = `dashboard/shipments/GET_PRE_SHIPMENT_DASHBOARD_FAILURE`,
  CLEAR_PRE_SHIPMENT_DASHBOARD_STATE = `dashboard/shipments/CLEAR_PRE_SHIPMENT_DASHBOARD_STATE`,
  CLEAR_MESSAGE_PRE_SHIPMENT_DASHBOARD_ERROR = `dashboard/shipments/CLEAR_MESSAGE_PRE_SHIPMENT_DASHBOARD_ERROR`,

  GET_DISPATCHER_DASHBOARD_REQUEST = `dashboard/shipments/GET_DISPATCHER_DASHBOARD_REQUEST`,
  GET_DISPATCHER_DASHBOARD_SUCCESS = `dashboard/shipments/GET_DISPATCHER_DASHBOARD_SUCCESS`,
  GET_DISPATCHER_DASHBOARD_FAILURE = `dashboard/shipments/GET_DISPATCHER_DASHBOARD_FAILURE`,
  CLEAR_DISPATCHER_DASHBOARD_STATE = `dashboard/shipments/CLEAR_DISPATCHER_DASHBOARD_STATE`,
  CLEAR_MESSAGE_DISPATCHER_DASHBOARD_ERROR = `dashboard/shipments/CLEAR_MESSAGE_DISPATCHER_DASHBOARD_ERROR`,

  CLEAR_STATE = `dashboard/shipments/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
//   | FSA<ActionType.TOKEN_REQUEST_SUCCESS>
//   | FSA<ActionType.TOKEN_REQUEST_FAILURE>
//   | FSA<ActionType.LOGIN_REQUEST>
//   | FSA<ActionType.LOGIN_SUCCESS>
//   | FSA<ActionType.LOGIN_FAILURE>
//   | FSA<ActionType.LOGOUT>
