import React from 'react';

import { twMerge } from 'tailwind-merge';

export interface SelectOptionInterface extends React.OptionHTMLAttributes<HTMLOptionElement> {
  label: string;
  value?: string | number | readonly string[];
  disabled?: boolean;
}

export interface selectOptionGroupInterface extends React.OptgroupHTMLAttributes<HTMLOptGroupElement> {}
export interface SelectInterface {
  name?: string;
  value?: string | number | null;
  className?: string;
  options?: (SelectOptionInterface | selectOptionGroupInterface)[];
}

const Select: React.ForwardRefRenderFunction<HTMLSelectElement, SelectInterface & React.SelectHTMLAttributes<HTMLSelectElement>> = (
  { options = [], className, ...props }: any,
  ref,
) => {
  const classes = twMerge(
    ...'select border rounded font-normal text-gray-400 border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-[13px] dark:text-gray-dark-400'.split(
      ' ',
    ),
    ...String(className || '').split(/\s+/),
  );
  return (
    <select className={classes} {...props} ref={ref}>
      {options.length > 0
        ? options.map(({ label, options = null, ...op }: any, idx: number) => {
            if (options !== undefined && Array.isArray(options)) {
              return (
                <optgroup label={label} key={idx} {...op}>
                  {options.map(({ label, value, ...attrs }, cidx) => {
                    return (
                      <option key={`${idx}-${cidx}`} value={value} {...attrs}>
                        {label}
                      </option>
                    );
                  })}
                </optgroup>
              );
            }
            const { value, ...attrs } = op;
            return (
              <option key={idx} value={value} {...attrs}>
                {label}
              </option>
            );
          })
        : ''}
    </select>
  );
};

export default React.forwardRef(Select);
