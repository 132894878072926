export function applyTheme(theme: any) {
  const root = document.documentElement;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.keys(theme).forEach((cssVar) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function createTheme({ primary, secondary, textBase }: any) {
  return {
    '--theme-primary': primary,
    '--theme-secondary': secondary,
    '--theme-text-base': textBase,
  };
}
