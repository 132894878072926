export default class ForbiddenError extends Error {
  private code = 500;
  public type = 'ForbiddenError';

  constructor(msg = 'Forbidden', code = 500) {
    super(msg);
    this.code = code;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}
