import { Action, ActionType } from '@store/interfaces/user/users';

const initialState: any = {
  loading: true,
  items: [],
  search_criteria: {},
};

export default function stats(state = initialState, action: Action) {
  switch (action.type) {
    case ActionType.GET_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: undefined,
        message: action?.message,
      };
    case ActionType.GET_ITEMS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
