import React, { useEffect, useState } from 'react';

export interface TextRangeInputInterface {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  placeholder?: string;
  value: any;
  defaultValue: any;
  onChange?: (value: any) => void;
}

const TextRangeInput: React.ForwardRefRenderFunction<HTMLInputElement, TextRangeInputInterface & React.InputHTMLAttributes<HTMLInputElement>> = (
  { size: _size, defaultValue, value, className: _className, placeholder: _placeholder, onChange, ...props },
  ref,
) => {
  const [val, setVal] = useState({ from: value?.from ?? defaultValue?.from ?? undefined, to: value?.to ?? defaultValue?.to ?? undefined });
  const [changed, setChanged] = useState(false);
  const partOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const name = evt.currentTarget.getAttribute('name');
    const value = evt.currentTarget.value;
    if (name) {
      setVal((val) => ({ ...val, [name]: value === '' ? undefined : value }));
      setChanged(true);
    }
  };
  const { from, to } = val;
  useEffect(() => {
    if (changed) {
      setChanged(false);
      onChange && onChange({ from, to });
    }
  }, [changed, from, onChange, to]);
  return (
    <span className="relative flex flex-wrap items-stretch">
      <span className="flex items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center dark:border-neutral-600 dark:text-neutral-200">
        From
      </span>
      <input
        className="input rounded-none relative m-0 block w-[1px] min-w-0 flex-auto border border-solid border-neutral-300 px-3 py-[0.25rem] focus:z-[3] focus:border-primary focus:outline-none dark:border-neutral-600 dark:focus:border-primary"
        defaultValue={value?.from || ''}
        {...props}
        name="from"
        onChange={partOnChange}
        ref={ref}
      />
      <span className="flex items-center whitespace-nowrap border border-x-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center dark:border-neutral-600 dark:text-neutral-200">
        To
      </span>
      <input
        className="input rounded rounded-l-none relative m-0 -ml-px block w-[1px] min-w-0 flex-auto border border-solid border-neutral-300 px-3 py-[0.25rem] focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:focus:border-primary"
        defaultValue={value?.to || ''}
        {...props}
        name="to"
        onChange={partOnChange}
        ref={ref}
      />
    </span>
  );
};

export default React.forwardRef(TextRangeInput);
