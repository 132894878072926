import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';

import { menus } from '@constants/menu';

import Aside from '@components/Aside';
import Footer from '@components/Footer';
import Header from '@components/Header';
import PageTitle from '@components/PageTitle';
import { useFirstMenuCanAccess } from '@hooks';

import Breadcrumb from './components/Breadcrumb';
import { PageTitleContext } from './context/PageTitleContext';
import ErrorBoundary from './ErrorBoundary';

function Forbidden() {
  const { pageTitle } = React.useContext(PageTitleContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // redirect login if no user
  const user = useSelector((state: any) => state.auth);
  const location = useLocation();
  const firstMenuCanAccess = useFirstMenuCanAccess(menus);
  if (!user || !user.loggedIn) {
    return <Navigate to="/login" replace />;
  }
  // if no permission homepage => access first menu if exists
  if ((location.pathname === '/' || location.pathname === '/dashboard') && firstMenuCanAccess) {
    // throw new Error('Loop redirect path');
    return <Navigate to={firstMenuCanAccess.to} replace />;
  }
  return (
    <ErrorBoundary>
      <div
        className={`wrapper mx-auto text-gray-900 font-normal grid grid-cols-[257px,1fr] grid-rows-[auto,1fr] min-h-screen ${
          isMobileMenuOpen ? 'active-menu' : ''
        }`}
        id="layout"
      >
        <Aside />
        <Header setIsMobileMenuOpen={setIsMobileMenuOpen} />
        <main className="flex flex-col pt-[42px] px-[23px] pb-[28px] overflow-hidden">
          <PageTitle title={pageTitle} />
          <Breadcrumb />
          <div className="border p-6 bg-neutral-bg rounded-2xl border-neutral pb-0 overflow-x-scroll scrollbar-hide dark:bg-dark-neutral-bg dark:border-dark-neutral-border mb-[52px] xl:overflow-x-hidden">
            <div className="text-base leading-5 text-gray-1100 font-semibold mb-6 dark:text-gray-dark-1100">
              You don't have permission to access this page
            </div>
          </div>
          <Footer />
          <section
            className={`fixed w-screen h-full cursor-pointer bg-gray-900 ${
              isMobileMenuOpen ? 'delay-300 transition-opacity opacity-25 translate-x-0' : 'transition-opacity delay-0 opacity-0 translate-x-full'
            }`}
            onClick={() => {
              setIsMobileMenuOpen(false);
            }}
          ></section>
        </main>
      </div>
    </ErrorBoundary>
  );
}

export default Forbidden;
