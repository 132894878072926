import React from 'react';

import ListItem, { ListItemInterface } from './ListItem';

export interface ListGroupIterface {
  label: string;
  items: ListItemInterface[];
  action?: any;
}

function ListGroup(props: ListGroupIterface) {
  const { label, items } = props;
  return (
    <div className="category-list">
      <div className="w-full bg-neutral h-[1px] mb-[21px] dark:bg-dark-neutral-border"></div>
      <h3 className="text-sm font-bold text-gray-1100 py-3 px-6 dark:text-gray-dark-1100">{label}</h3>
      <div>
        {items.length &&
          items.map((item: any, idx: number) => {
            return <ListItem key={idx} {...item} />;
          })}
      </div>
      {/* <ListItemAction /> */}
    </div>
  );
}

export default ListGroup;
