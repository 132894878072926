import React, { useCallback } from 'react';

import { Icon, ToggleSwitch } from '@components/base';
import { ThemeContext } from '@themes/ThemeContext';

function ThemeSwitch() {
  const { theme, setTheme } = React.useContext(ThemeContext);
  const changeTheme = (event: any) => {
    if (event.target.checked) {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  const toggleFullscreen = useCallback(() => {
    const e: Window & any = window.document;
    const t: Document & any = e.documentElement;
    const a = t.requestFullscreen || t.mozRequestFullScreen || t.webkitRequestFullScreen || t.msRequestFullscreen;
    const l = e.exitFullscreen || e.mozCancelFullScreen || e.webkitExitFullscreen || e.msExitFullscreen;
    e.fullscreenElement || e.mozFullScreenElement || e.webkitFullscreenElement || e.msFullscreenElement ? l.call(e) : a.call(t);
  }, []);

  return (
    <div className="rounded-xl bg-neutral pt-4 flex items-center gap-5 mt-5 sidebar-control pr-[18px] pb-[13px] pl-[19px] dark:bg-dark-neutral-border theme-ctrl-wrap flex flex-row">
      <div className="flex items-center gap-3 theme-ctrl-theme-switch">
        <i className="moon-icon" id="theme-toggle-dark-icon">
          <Icon className="cursor-pointer" name="moon" onClick={() => setTheme('dark')} alt="moon icon" />
          <Icon className="cursor-pointer" name="moon-active" onClick={() => setTheme('light')} alt="moon icon" />
        </i>
        <ToggleSwitch id="toggle-theme-btn" name="theme-toggle" type="outer" onChange={changeTheme} checked={theme == 'light'} />
        <i className="sun-icon" id="theme-toggle-light-icon">
          <Icon className="cursor-pointer" name="sun" onClick={() => setTheme('light')} alt="sun icon" />
          <Icon className="cursor-pointer" name="sun-active" onClick={() => setTheme('dark')} alt="sun icon" />
        </i>
      </div>
      <div className="bg-neutral-bg w-[2px] h-[30px] dark:bg-dark-neutral-bg theme-ctrl-separator"></div>
      <div className="theme-ctrl-fullscreen">
        <Icon className="cursor-pointer" id="sidebar-expand" name="maximize-3" alt="expand icon" onClick={toggleFullscreen} />
      </div>
    </div>
  );
}

export default ThemeSwitch;
