import { ObjectInterface } from '@interfaces';

import { PERMISSION } from '@constants/permissions';

export const matchPermission = (id: string | string[], permissions: ObjectInterface, orQuery = false): boolean => {
  if (!id || (id && !id.length)) {
    return true;
  }
  const ids = Array.isArray(id) ? id.slice() : [id];
  return (
    ids.reduce((res: null | boolean, id) => {
      if (res === true && orQuery) {
        return true;
      } else if (res === false && !orQuery) {
        return false;
      }

      const permission = permissions[id];
      const allPermission = permissions[PERMISSION.ADMIN];
      if (allPermission && allPermission.permission === 'allow') {
        return true;
      }
      if (permission && permission.permission === 'allow') {
        return true;
      }
      return false;
    }, null) === true
  );
};
