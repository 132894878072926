import { ActionType, AuthState } from '@store/interfaces/auth';

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  user: null,
};

export default function auth(state = initialState, action: any) {
  const { payload, error } = action;
  switch (action.type) {
    case ActionType.TOKEN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        error: undefined,
      };
    case ActionType.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case ActionType.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        user: payload.user,
        error: undefined,
      };
    case ActionType.UPDATE_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
      };
    case ActionType.USER_INFO_SUCCESS:
      return {
        ...state,
        user: {
          ...(state.user ? state.user : {}),
          ...payload,
        },
      };
    case ActionType.CLEAR_LOGIN_STATE:
      return {
        ...state,
        loggingIn: undefined,
        error: undefined,
      };
    case ActionType.LOGIN_FAILURE:
      return {
        error,
      };
    case ActionType.LOGOUT:
      return {};
    default:
      return state;
  }
}
