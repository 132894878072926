import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/shipments/shipout-waiting';
import { Action, ActionType } from '@store/interfaces/shipments/shipout-waiting';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getListRequest({ payload }: Action) {
  try {
    const params: any = { ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getListShipoutWaiting.bind(magentoService), params);
    yield put(actions.getListSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getListFailure(error?.message));
  }
}

function* getListRequestSaga() {
  yield takeLatest(ActionType.GET_SHIPOUT_WAITING_REQUEST, getListRequest);
}
export default getListRequestSaga;
