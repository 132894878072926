export enum ActionType {
  GET_COUNTRIES_REQUEST = 'share/GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS = 'share/GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE = 'share/GET_COUNTRIES_FAILURE',

  GET_ALL_STORE_REQUEST = 'share/GET_ALL_STORE_REQUEST',
  GET_ALL_STORE_SUCCESS = 'share/GET_ALL_STORE_SUCCESS',
  GET_ALL_STORE_FAILURE = 'share/GET_ALL_STORE_FAILURE',

  GET_DISPATCHERS_REQUEST = 'share/GET_DISPATCHERS_REQUEST',
  GET_DISPATCHERS_CACHED = 'share/GET_DISPATCHERS_CACHED',
  GET_DISPATCHERS_SUCCESS = 'share/GET_DISPATCHERS_SUCCESS',
  GET_DISPATCHERS_FAILURE = 'share/GET_DISPATCHERS_FAILURE',

  GET_ALL_STATUS_LABEL_REQUEST = 'share/GET_ALL_STATUS_LABEL_REQUEST',
  GET_ALL_STATUS_LABEL_SUCCESS = 'share/GET_ALL_STATUS_LABEL_SUCCESS',
  GET_ALL_STATUS_LABEL_FAILURE = 'share/GET_ALL_STATUS_LABEL_FAILURE',

  GET_USERS_REQUEST = 'share/GET_USERS_REQUEST',
  GET_USERS_REQUEST_SUCCESS = 'share/GET_USERS_REQUEST_SUCCESS',
  GET_USERS_REQUEST_FAILURE = 'share/GET_USERS_REQUEST_FAILURE',

  SET_CURRENT_DISPATCHER = 'share/SET_CURRENT_DISPATCHER',

  GET_SHARE_DATA_REQUEST = 'share/GET_SHARE_DATA_REQUEST',

  GET_TICKET_CONFIG_REQUEST = 'share/GET_TICKET_CONFIG_REQUEST',
  CACHE_INVALIDATE_TICKET_CONFIG = 'share/CACHE_INVALIDATE_TICKET_CONFIG',
  GET_TICKET_CONFIG_REQUEST_SUCCESS = 'share/GET_TICKET_CONFIG_REQUEST_SUCCESS',
  GET_TICKET_CONFIG_REQUEST_FAILURE = 'share/GET_TICKET_CONFIG_REQUEST_FAILURE',

  CLEAR_SHARE_DATA = 'share/GET_CLEAR_SHARE_DATA',
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
