import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';

import { BASE_API_ENDPOINT, oauth, PUBLIC_URL } from '@constants';
import { strings } from '@helpers';

import { ApiClient } from './api';

const api = new ApiClient(BASE_API_ENDPOINT, { 'User-Agent': process.env.REACT_APP_API_USER_AGENT ?? 'glamira-backoffice' });
class AuthService {
  private _refreshToken: string | null = null;

  setRefreshToken(token: string | null) {
    this._refreshToken = token;
  }
  getRefreshToken() {
    return this._refreshToken;
  }
  login(username: string, password: string) {
    return (
      api
        // for repath (rest/V1 - wrap proxy from backend
        .post('/../../magento-api/integration/admin/token', {
          username,
          password,
        })
        .then((response) => {
          return response.data;
        })
    );
  }

  fetchUserPermissions(token: string, userId?: string | number | null) {
    const conf: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (userId) {
      return api.get(`/../../magento-api/return-manage/team-members/${userId}/permissions?searchCriteria[page_size]=0`, conf);
    }
    return api.get(`/../../magento-api/return-manage/team-members/me/permissions/?searchCriteria[page_size]=0`, conf);
  }

  logout() {
    localStorage.clear();
  }

  register(username: string, email: string, password: string) {
    return api.post('/auth/signup', {
      username,
      email,
      password,
    });
  }

  loginByCode(code: string) {
    const url = `/auth/magento/token?${qs.stringify({ code })}&_=${Date.now()}`;
    return api.get(url);
  }

  refreshToken(token: string) {
    // return api
    //   .post('/../../magento-api/adminsso/authorize/token', {
    //     client_id: 'd98d70a8dfaed8979ebd5ddedebb4596',
    //     client_secret: '0f89d9507ef39dbc79d7a048dd024642',
    //     redirect_uri: 'http://localhost:3000/oauth-callback',
    //     grant_type: 'refresh_token',
    //     refresh_token: token,
    //   })
    //   .then((response) => {
    //     return response.data;
    //   });
    return api
      .post('/auth/magento/refresh-token', {
        refresh_token: token,
      })
      .then((response) => {
        if (!response?.data?.data) {
          throw new Error('Invalid response');
        }
        const { refresh_token: refreshToken, access_token: accessToken, ...props } = response?.data?.data || {};

        return {
          refreshToken,
          accessToken,
          ...props,
        };
      });
  }

  getOAuthUri(type: string) {
    if (!oauth[type]) {
      throw new Error(`Invalid oauth provider: ${type}`);
    }
    const authConf = oauth[type];
    const { authorize_url, redirect_uri, client_id } = authConf;
    let redirectUri: string = redirect_uri || '';
    if (redirectUri.match(/^http(s)?:\/\//g) === null) {
      redirectUri = `${PUBLIC_URL}/${strings.ltrim(redirectUri, '/')}`;
    }
    const query = qs.stringify({
      response_type: 'code',
      redirect_uri: redirectUri,
      client_id,
    });
    return `${authorize_url}?${query}`;
  }
}

const auth = new AuthService();
let updateAccessToken: (token?: string) => void = () => undefined;
let onUnauthenticated: () => void = () => undefined;
let refreshTokenRequest: any = null;
const ignoreUrls = ['/auth/magento/refresh-token', '/auth/magento/token'];

const refreshToken = async (error: AxiosError) => {
  const originalConfig = {
    headers: {},
    _retry: false,
    ...(error.config || {}),
  };

  const refreshToken = auth.getRefreshToken();
  if (!refreshToken) {
    onUnauthenticated && onUnauthenticated();
    return null;
  }
  try {
    if (!refreshTokenRequest) {
      refreshTokenRequest = auth.refreshToken(refreshToken);
    }
    const { accessToken }: { accessToken?: string } = await refreshTokenRequest;
    console.log(accessToken);
    updateAccessToken && updateAccessToken(accessToken);

    originalConfig.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    originalConfig._retry = true;

    return axios(originalConfig).then((res) => {
      refreshTokenRequest = null;
      return res;
    });
  } catch (err) {
    onUnauthenticated && onUnauthenticated();
  }
  refreshTokenRequest = null;
  return null;
};

export const withRefreshTokenAndRetry = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const originalConfig = {
        headers: {},
        _retry: false,
        ...(error.config || {}),
      };
      if (
        error.response?.status !== 401 ||
        (originalConfig._retry && error.response?.status === 401) ||
        (originalConfig.url && ignoreUrls.includes(originalConfig.url))
      ) {
        return Promise.reject(error);
      }
      return refreshToken(error).then((response) => {
        if (!response) {
          return Promise.reject(error);
        }
        return response;
      });
    },
  );
};

export function registerStore(store: any, updateToken: (token?: string) => void, logout: () => void) {
  store.subscribe(listener);
  updateAccessToken = updateToken;
  onUnauthenticated = logout;

  function select(state: any) {
    return (state?.auth?.refreshToken?.toString() as string) || null;
  }

  function listener() {
    auth.setRefreshToken(select(store.getState()));
  }
}
export default auth;
