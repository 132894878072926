import { Action, ActionType } from '@store/interfaces/shipments/packages-wait-carrier-ship';

const getPackages = (payload: any): Action => {
  return { type: ActionType.GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST, payload };
};
const getPackagesSuccess = (payload: any): Action => {
  return { type: ActionType.GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST_SUCCESS, payload };
};
const getPackagesFailure = (error?: any): Action => {
  return { type: ActionType.GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getPackages,
  getPackagesSuccess,
  getPackagesFailure,

  clearMessageError,
};

const returns = {
  actions,
};

export default returns;
