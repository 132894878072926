import qs from 'qs';

import { ObjectInterface } from '@interfaces';

import { escapeRegExp } from './strings';

export const buildPath = (path: string, params: ObjectInterface = {}) => {
  const keys = Object.keys(params);
  if (!keys.length) {
    return path;
  }
  const regex = new RegExp(':(' + keys.map((key: string) => escapeRegExp(`${key}`)).join('|') + ')', 'g');
  return path.replace(regex, (_, key: string) => {
    const value: string = params[key]?.toString() || '';
    return encodeURIComponent(value);
  });
};

export const buildUrl = (path: string, params: ObjectInterface = {}, query: ObjectInterface = {}) => {
  const q = query ? qs.stringify(query || {}) : '';
  return buildPath(path, params) + (q ? '?' : '') + q;
};
