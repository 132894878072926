import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { Icon } from '@components/base';
import usePageTitle from '@hooks/usePageTitle';

import { breadcrumb as routes } from '~/routes';

export const Breadcrumb = () => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const { setPageTitle } = usePageTitle();
  const breadcrumbs = useBreadcrumbs(routes);
  useEffect(() => {
    if (ref.current) {
      setPageTitle(ref.current.innerText);
    }
  });
  return (
    <div className="flex items-center text-xs text-gray-500 gap-x-[11px] mb-[37px] breadcrumb-wrap">
      {breadcrumbs.map(({ match, breadcrumb }, idx: number) => {
        const isLastItem = idx + 1 === breadcrumbs.length;
        const isFirstItem = idx === 0;
        if (isLastItem) {
          return (
            <span key={match.pathname} className="capitalize text-color-brands breadcrumb-item last" ref={ref}>
              {breadcrumb}
            </span>
          );
        }
        return (
          <div key={match.pathname} className="flex items-center gap-x-1 breadcrumb-item">
            {isFirstItem && <Icon name="home-2" />}
            <NavLink to={match.pathname} className="capitalize text-gray-500 dark:text-gray-dark-500">
              {breadcrumb}
            </NavLink>
            <Icon name="arrow-right" />
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
