import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRouteError } from 'react-router-dom';

import Footer from '@components/Footer';
import Forbidden from '@layouts/Forbidden';

import ForbiddenError from '~/errors/Forbidden';

const errorMessage: any = {
  'Not Found': "Sorry, we couldn't find this page.",
};

export function RootError(): JSX.Element {
  const err = useRouteError() as RouteError;

  const msg = err.statusText ?? err.message;
  const code = err.status || 500;
  const { t } = useTranslation();

  if (err instanceof ForbiddenError || code == 403) {
    return <Forbidden />;
  }
  return (
    <div className="wrapper mx-auto text-gray-900 font-normal min-h-screen" id="layout">
      <main className="overflow-x-scroll flex flex-col justify-between pt-[42px] px-[23px] pb-[28px] min-h-screen overflow-x-clip">
        <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
            <div className="max-w-lg text-center">
              <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
                <span className="sr-only">Error</span> {code}
              </h2>
              <p className="text-2xl font-semibold md:text-3xl">{errorMessage[msg] ?? msg}</p>
              {code == 404 && <p className="mt-4 mb-8 dark:text-gray-400">But dont worry, you can find plenty of other things on our homepage.</p>}
              {code == 500 && <p className="mt-4 mb-8 dark:text-gray-400">Something went wrong, Try reloading the page OR go homepage.</p>}
              <p className="mt-4 mb-8 dark:text-gray-400"></p>
              <Link
                rel="noopener noreferrer"
                to="/"
                className="px-8 py-3 font-semibold border rounded-lg gap-x-[10px] border-[#E8EDF2] p-[3px] py-[14px] sm:min-w-[170px]"
              >
                {t('Back to homepage')}
              </Link>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </div>
  );
}

export type RouteError = Error & { status?: number; statusText?: string };
