export enum ActionType {
  GET_RETURN_TYPE_REQUEST = 'returns/share/GET_RETURN_TYPE_REQUEST',
  GET_RETURN_TYPE_SUCCESS = 'returns/share/GET_RETURN_TYPE_SUCCESS',
  GET_RETURN_TYPE_FAILURE = 'returns/share/GET_RETURN_TYPE_FAILURE',

  GET_RETURN_STATUS_REQUEST = 'returns/share/GET_RETURN_STATUS_REQUEST',
  GET_RETURN_STATUS_SUCCESS = 'returns/share/GET_RETURN_STATUS_SUCCESS',
  GET_RETURN_STATUS_FAILURE = 'returns/share/GET_RETURN_STATUS_FAILURE',

  GET_RETURN_ITEM_STATE_REQUEST = 'returns/share/GET_RETURN_ITEM_STATE_REQUEST',
  GET_RETURN_ITEM_STATE_SUCCESS = 'returns/share/GET_RETURN_ITEM_STATE_SUCCESS',
  GET_RETURN_ITEM_STATE_FAILURE = 'returns/share/GET_RETURN_ITEM_STATE_FAILURE',

  GET_RETURN_SHARE_DATA_REQUEST = 'returns/share/GET_RETURN_SHARE_DATA_REQUEST',

  CLEAR_MESSAGE_ERROR = 'returns/share/GET_RETURN_CLEAR_MESSAGE_ERRORSHARE_DATA_REQUEST',
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
