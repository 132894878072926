import React from 'react';

import { twMerge } from 'tailwind-merge';

export interface TextAreaInterface {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  placeholder?: string;
}

const TextArea: React.ForwardRefRenderFunction<HTMLTextAreaElement, TextAreaInterface & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (
  { size, defaultValue, value, className, ...props },
  ref,
) => {
  const defVal = defaultValue ?? '';
  const inputSize = size ? `text-${size}` : '';
  const attrs: { [key: string]: string } | any = { value, defaultValue };
  if (defaultValue === undefined && value === undefined) {
    attrs.defaultValue = defVal;
  }
  const classes = twMerge('input rounded input-bordered w-full focus:outline-none dark:text-gray-dark-1400', inputSize, className || '');
  return <textarea className={classes} {...attrs} {...props} ref={ref} />;
};

export default React.forwardRef(TextArea);
