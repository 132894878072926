import { Action, ActionType } from '@store/interfaces/shipments/shipping-service-detail';

const initialState: any = { loading: true, tracking_info: [] };

export default function packages(state = initialState, action: Action) {
  switch (action.type) {
    case ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: undefined,
        message: action?.message,
      };
    case ActionType.GET_SHIPPING_SERVICE_DETAIL_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
