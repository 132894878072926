export enum ActionType {
  GET_ORDERS_REQUEST = 'aftership/orders/GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS = 'aftership/orders/GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE = 'aftership/orders/GET_ORDERS_FAILURE',
  CLEAR_MESSAGE_ORDERS_ERROR = 'aftership/orders/CLEAR_MESSAGE_ERROR',
  CLEAR_ORDERS_STATE = 'aftership/orders/CLEAR_ORDERS_STATE',

  REFRESH_ORDERS_REQUEST = 'aftership/orders/REFRESH_ORDERS_REQUEST',
  REFRESH_ORDERS_SUCCESS = 'aftership/orders/REFRESH_ORDERS_SUCCESS',
  REFRESH_ORDERS_FAILURE = 'aftership/orders/REFRESH_ORDERS_FAILURE',

  GET_CARRIERS_REQUEST = 'aftership/orders/GET_CARRIERS_REQUEST',
  GET_CARRIERS_SUCCESS = 'aftership/orders/GET_CARRIERS_SUCCESS',
  GET_CARRIERS_FAILURE = 'aftership/orders/GET_CARRIERS_FAILURE',

  GET_COUNTRIES_REQUEST = 'aftership/orders/GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS = 'aftership/orders/GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE = 'aftership/orders/GET_COUNTRIES_FAILURE',

  EXPORT_ORDERS_REQUEST = 'aftership/orders/EXPORT_ORDERS_REQUEST',
  EXPORT_ORDERS_SUCCESS = 'aftership/orders/EXPORT_ORDERS_SUCCESS',
  EXPORT_ORDERS_FAILURE = 'aftership/orders/EXPORT_ORDERS_FAILURE',

  GET_ORDER_DETAIL_REQUEST = 'aftership/orders/GET_ORDER_DETAIL_REQUEST',
  GET_ORDER_DETAIL_SUCCESS = 'aftership/orders/GET_ORDER_DETAIL_SUCCESS',
  GET_ORDER_DETAIL_FAILURE = 'aftership/orders/GET_ORDER_DETAIL_FAILURE',
  CLEAR_ORDER_DETAIL = 'aftership/orders/CLEAR_ORDER_DETAIL',

  GET_REPORT_DASHBOARD_REQUEST = 'aftership/orders/GET_REPORT_DASHBOARD_REQUEST',
  GET_REPORT_DASHBOARD_SUCCESS = 'aftership/orders/GET_REPORT_DASHBOARD_SUCCESS',
  GET_REPORT_DASHBOARD_FAILURE = 'aftership/orders/GET_REPORT_DASHBOARD_FAILURE',

  CLEAR_MESSAGE_ERROR = 'aftership/orders/CLEAR_MESSAGE_ERROR',
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
//   | FSA<ActionType.TOKEN_REQUEST_SUCCESS>
//   | FSA<ActionType.TOKEN_REQUEST_FAILURE>
//   | FSA<ActionType.LOGIN_REQUEST>
//   | FSA<ActionType.LOGIN_SUCCESS>
//   | FSA<ActionType.LOGIN_FAILURE>
//   | FSA<ActionType.LOGOUT>
