import React from 'react';
import { Link } from 'react-router-dom';

import { APP_VERSION } from '@constants';

function Copyright() {
  const year = new Date().getFullYear();
  return (
    <div className="flex items-center gap-2 flex-wrap">
      <p>
        <span>© {year} &nbsp;</span>
        <Link to={'/'} className="text-color-brands">
          BackOffice {APP_VERSION}
        </Link>
        {' - '}
        <a href="https://www.glamira.com" className="text-color-brands">
          Glamira
        </a>
      </p>
    </div>
  );
}

export default Copyright;
