import React, { useCallback, useEffect, useState } from 'react';

export interface ToggleSwitchInterface {
  name?: string;
  id?: string;
  type?: 'outer' | 'inner';
  inputId?: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
}

let autoIdx = 0;
const getIdx = () => ++autoIdx;

export const ToggleSwitch: React.FC<ToggleSwitchInterface> = ({ id, inputId, name, checked, type = 'inner', onChange, ...props }) => {
  const [active, setActive] = useState(checked);
  const [idx, _setIdx] = useState<string>(id || `switch-${getIdx()}`);

  const inputIdx = inputId || `${idx}-input`;

  useEffect(() => {
    setActive(checked);
  }, [checked, setActive]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = Boolean(event?.target?.checked || false);
      setActive(checked);
      onChange && onChange(event);
    },
    [onChange],
  );
  let cursorClass = 'cursor-pointer';
  if (props.disabled) {
    cursorClass = 'cursor-not-allowed';
  } else if (props.readOnly) {
    cursorClass = 'cursor-normal';
  }
  if (type == 'inner') {
    const dotClass = active ? 'translate-x-4' : '';
    const ringClass = active ? 'bg-indigo-600 ring-black/20' : 'bg-slate-900/10 ring-slate-900/5';
    return (
      <label className={`flex items-center ${cursorClass}`} htmlFor={inputIdx} id={id}>
        <div className="relative">
          <input className="sr-only peer" type="checkbox" name={name || idx} checked={active} id={inputIdx} onChange={handleOnChange} {...props} />
          <div
            className={`pointer-events-auto h-6 w-10 rounded-full p-1 ring-1 ring-inset transition duration-200 ease-in-out peer-checked:bg-color-brands ${ringClass}`}
          >
            <div className={`h-4 w-4 rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out ${dotClass}`}></div>
          </div>
        </div>
      </label>
    );
  }
  return (
    <label className={`flex items-center ${cursorClass}`} htmlFor={inputIdx} id={id}>
      <div className="relative">
        <input className="sr-only peer" type="checkbox" name={name || idx} checked={active} id={inputIdx} onChange={handleOnChange} {...props} />
        <div className="block rounded-full w-[48px] h-[16px] bg-gray-300 peer-checked:bg-[#B2A7FF]"></div>
        <div className="dot dotS absolute rounded-full transition h-[24px] w-[24px] top-[-4px] left-[-4px] bg-[#B2A7FF] peer-checked:bg-color-brands"></div>
      </div>
    </label>
  );
};

export default ToggleSwitch;
