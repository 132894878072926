import { Action, ActionType } from '@store/interfaces/share';

const getCountries = (): Action => {
  return { type: ActionType.GET_COUNTRIES_REQUEST };
};
const getCountriesSuccess = (payload: any): Action => {
  return { type: ActionType.GET_COUNTRIES_SUCCESS, payload };
};
const getCountriesFailure = (error?: any): Action => {
  return { type: ActionType.GET_COUNTRIES_FAILURE, error };
};

export const actions = {
  getCountries,
  getCountriesSuccess,
  getCountriesFailure,
};

const country = {
  actions,
};

export default country;
