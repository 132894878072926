import React from 'react';
import { useSelector } from 'react-redux';

import { formatMoney } from 'accounting';
import { getProperty } from 'dot-prop';
import { twMerge } from 'tailwind-merge';

import { formatDate, formatDatetime, isFunc } from '@helpers';

import { CopyBox as BaseCopyBox } from '@components/base/CopyBox';

import { CopyBox } from './Renderer/CopyBox';
import { IColumnType } from './Table';

const currencySymbols: { [key: string]: string } = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};
interface TableCellProps {
  item: any;
  className?: string;
  column: IColumnType;
}

export const TableCell: React.FC<TableCellProps> = ({ item, column, className = '' }) => {
  const { key, format, formatString, timezone, copyBox, cellComponent, actionComponent } = column;
  const value = item[key] || getProperty(item, key);
  let renderValue = value;
  let isRawRender = false;
  const userTimezone: string | undefined = useSelector((state: any) => state?.auth?.user?.timezone);
  let tz = timezone || userTimezone;
  if (typeof tz === 'undefined') {
    tz = localStorage.getItem('timezone') || 'UTC';
  }
  if (format) {
    if (typeof format === 'string') {
      switch (format) {
        case 'date':
          renderValue = formatDate(value, formatString || 'yyyy-MM-dd', tz);
          break;
        case 'datetime':
          renderValue = formatDatetime(value, formatString || 'yyyy-MM-dd HH:mm:ss', tz);
          break;
        case 'currency':
          const { currencyKey } = column;
          const symbol = getProperty(item, currencyKey) ?? '';
          renderValue = value ? formatMoney(+value, { symbol: currencySymbols[symbol] ?? symbol }) : value;
          break;
        case 'raw':
          isRawRender = true;
          break;
        default:
          console.log(`Format not process: ${format}`);
      }
    } else if (isFunc(format)) {
      renderValue = format(value, item, key, column);
      isRawRender = true;
    } else {
      console.log('Format value invalid', format);
    }
  }
  const { filter, options } = column;
  if ((filter == 'select' || filter == 'multipleSelect') && options && options.length) {
    options.forEach(({ label, value: val }: any) => {
      if (value?.toString() === val?.toString()) {
        renderValue = label;
      }
    });
    options.forEach(({ value: parentValue }: any) => {
      const { options: childs } = value === null || value === undefined ? ({} as any) : parentValue;
      if (Array.isArray(childs) && childs.length) {
        childs.forEach(({ label, value: val }: any) => {
          if (value?.toString() === val?.toString()) {
            renderValue = label;
          }
        });
      }
    });
  }
  renderValue = renderValue === null || renderValue === undefined ? '' : renderValue;
  const classes = twMerge(className, column.cellClass || '');
  if (cellComponent) {
    const Component = cellComponent;
    return (
      <td className={classes}>
        {copyBox ? (
          <BaseCopyBox type="text">
            <Component item={item} dataKey={key} value={value} renderValue={renderValue} cellOptions={column} />
          </BaseCopyBox>
        ) : (
          <Component item={item} dataKey={key} value={value} renderValue={renderValue} cellOptions={column} />
        )}
      </td>
    );
  }

  if (actionComponent) {
    const Component = actionComponent;
    return (
      <td className={classes}>
        <Component item={item} />
      </td>
    );
  }

  if (isRawRender) {
    return (
      <td className={classes}>
        {copyBox ? (
          <BaseCopyBox type={column.dataCopyType || 'text'}>
            <div className={column.titleClass || ''} data-value={value} dangerouslySetInnerHTML={{ __html: renderValue }}></div>
          </BaseCopyBox>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: renderValue }}></div>
        )}
      </td>
    );
  }
  if (copyBox) {
    return (
      <td className={classes}>
        <CopyBox type={column.dataCopyType || 'text'} item={item} dataKey={key} value={renderValue} cellOptions={column} />
      </td>
    );
  }
  return <td className={classes}>{renderValue}</td>;
};

export default TableCell;
