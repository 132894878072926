import { Action, ActionType } from '@store/interfaces/returns/dashboard-detail';

const getReturnsDetailItems = (payload: any): Action => {
  return { type: ActionType.GET_DASHBOARD_RETURNS_DETAIL, payload };
};
const getReturnsDetailItemsSuccess = (payload: any): Action => {
  return { type: ActionType.GET_DASHBOARD_RETURNS_DETAIL_SUCCESS, payload };
};
const getReturnsDetailItemsFailure = (error?: any): Action => {
  return { type: ActionType.GET_DASHBOARD_RETURNS_DETAIL_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

const clearData = (): Action => {
  return { type: ActionType.CLEAR_STATE };
};

export const actions = {
  getReturnsDetailItems,
  getReturnsDetailItemsSuccess,
  getReturnsDetailItemsFailure,

  clearMessageError,
  clearData,
};

const returns = {
  actions,
};

export default returns;
