import React, { useEffect, useState } from 'react';
import BaseDatePicker from 'react-tailwindcss-datepicker';

import { twMerge } from 'tailwind-merge';

export interface DatePickerInterface {
  value?: Date;
  onChange?: (value: any) => void;
  className?: string;
}

export function DatePicker({ value, onChange, className, ...props }: DatePickerInterface & any) {
  const [val, setVal] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const handleValueChange = (newValue: any) => {
    setVal(newValue);
    onChange && onChange(newValue.startDate);
  };
  useEffect(() => {
    setVal({
      startDate: value,
      endDate: value,
    });
  }, [value]);
  const classes = twMerge('datepicker-wrap', String(className || ''));
  return (
    <div className={classes}>
      <BaseDatePicker
        primaryColor={'blue'}
        inputClassName="input input-bordered focus:outline-none dark:text-gray-dark-1400 rounded w-full"
        displayFormat={'YYYY/MM/DD'}
        value={val}
        onChange={handleValueChange}
        asSingle={true}
        useRange={false}
        i18n="en"
        {...props}
      />
    </div>
  );
}

export default DatePicker;
