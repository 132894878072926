import { Action, ActionType } from '@store/interfaces/report/late-item-stats';

const initialState: any = {
  production: { loading: true, items: [], search_criteria: {} },
  shipment: { loading: true, items: [], search_criteria: {} },
  return: { loading: true, items: [], search_criteria: {} },
};

export default function stats(state = initialState, action: Action) {
  switch (action.type) {
    case ActionType.GET_SHIPMENT_STATS_REQUEST:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          loading: true,
        },
      };
    case ActionType.GET_SHIPMENT_STATS_SUCCESS:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          ...action.payload,
          loading: false,
          error: undefined,
          message: action?.message,
        },
      };
    case ActionType.GET_SHIPMENT_STATS_FAILURE:
      return {
        ...state,
        shipment: {
          ...state.shipment,
          loading: false,
          error: action?.error,
        },
      };

    case ActionType.GET_RETURN_STATS_REQUEST:
      return {
        ...state,
        return: {
          ...state.delivery,
          loading: true,
        },
      };
    case ActionType.GET_RETURN_STATS_SUCCESS:
      return {
        ...state,
        return: {
          ...state.return,
          ...action.payload,
          loading: false,
          error: undefined,
          message: action?.message,
        },
      };
    case ActionType.GET_RETURN_STATS_FAILURE:
      return {
        ...state,
        return: {
          ...state.return,
          loading: false,
          error: action?.error,
        },
      };

    case ActionType.GET_PRODUCTION_STATS_REQUEST:
      return {
        ...state,
        production: {
          ...state.production,
          loading: true,
        },
      };
    case ActionType.GET_PRODUCTION_STATS_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          ...action.payload,
          loading: false,
          error: undefined,
          message: action?.message,
        },
      };
    case ActionType.GET_PRODUCTION_STATS_FAILURE:
      return {
        ...state,
        production: {
          ...state.production,
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
