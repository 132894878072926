import React from 'react';

import { twMerge } from 'tailwind-merge';

export interface RadioInterface {}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, RadioInterface & React.InputHTMLAttributes<HTMLInputElement>> = (
  { defaultValue, value, className, ...props },
  ref,
) => {
  const defVal = defaultValue ?? '';
  const attrs: { [key: string]: string } | any = { value, defaultValue };
  if (defaultValue === undefined && value === undefined) {
    attrs.defaultValue = defVal;
  }
  const classes = twMerge('radio radio-primary', className || '');
  return <input className={classes} {...attrs} {...props} type="radio" ref={ref} />;
};

export default React.forwardRef(Input);
