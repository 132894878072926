import React from 'react';
import Select from 'react-tailwindcss-select';

import { twMerge } from 'tailwind-merge';

export interface Select2OptionInterface extends React.OptionHTMLAttributes<HTMLOptionElement> {
  label: string;
  value?: string | number | readonly string[];
  disabled?: boolean;
}

export interface Select2OptionGroupInterface extends React.OptgroupHTMLAttributes<HTMLOptGroupElement> {}
export interface Select2Interface {
  name?: string;
  value?: string | number | null;
  className?: string;
  options?: (Select2OptionInterface | Select2OptionGroupInterface)[];
}

const Select2: React.ForwardRefRenderFunction<HTMLSelectElement, Select2Interface & React.SelectHTMLAttributes<HTMLSelectElement>> = (
  {
    options: rawOptions = [],
    multiple,
    name,
    disabled,
    placeholder: rawPlaceholder,
    className,
    isClearable = true,
    isSearchable = true,
    value: rawValue,
    onChange,
    ...props
  }: any,
  ref,
) => {
  const classes = twMerge(
    ...'select border rounded font-normal text-gray-400 border-[#E8EDF2] dark:border-[#313442] focus:outline-none pl-[13px] dark:text-gray-dark-400'.split(
      ' ',
    ),
    props.multiple ? '!min-h-[100px]' : '',
    ...String(className || '').split(/\s+/),
  );
  const isMultiple = !!multiple;
  const handleChange = (item: any) => {
    if (!onChange) {
      return;
    }
    if (isMultiple) {
      const vals = (item || []).reduce((res: any[], it: any) => {
        res.push(it.value);
        return res;
      }, []);
      onChange({
        name,
        value: Array.isArray(vals) && vals.length === 0 ? null : vals,
        meta: item
          ? {
              ...item,
            }
          : null,
      });
    } else {
      onChange({
        name,
        value: item?.value,
        meta: item
          ? {
              ...item,
            }
          : null,
      });
    }
  };
  let placeholder = rawPlaceholder;
  const options = [...rawOptions].reduce((res, item: any) => {
    if (item.label == ' ' && item.value == '0') {
      return res;
    }
    if (item.value === '' || item.placeholder) {
      placeholder = placeholder ?? item.placeholder ?? item.label;
    } else {
      res.push({ ...item });
    }
    return res;
  }, []);
  const value = (options || []).reduce((res: any, val: any) => {
    if (isMultiple) {
      if (res === null) {
        res = [];
      }
      const { options: childs } = val;
      if (childs && childs.length) {
        (childs || []).forEach((child: any) => {
          if (val.disabled !== true && rawValue.includes(child.value) && child.disabled !== true) {
            res.push({ ...child });
          }
        });
      } else if (val.disabled !== true && rawValue.includes(val.value)) {
        res.push({ ...val });
      }
    } else if (val.value == rawValue && val.disabled !== true) {
      return { ...val };
    }
    return res;
  }, null);

  return (
    <Select
      className={classes}
      {...props}
      placeholder={placeholder}
      options={options}
      value={Array.isArray(value) && value.length === 0 ? null : value}
      onChange={handleChange}
      isMultiple={isMultiple}
      isDisabled={!!disabled}
      isSearchable={isSearchable}
      isClearable={isClearable}
      ref={ref}
    />
  );
};

export default React.forwardRef(Select2);
