export enum ActionType {
  GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST = `shipments/package-order/GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST`,
  GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST_SUCCESS = `shipments/package-order/GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST_SUCCESS`,
  GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST_FAILURE = `shipments/package-order/GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST_FAILURE`,

  CLEAR_MESSAGE_ERROR = `shipments/packages/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `shipments/packages/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
