import { getProperty } from 'dot-prop';

import { CopyBox as BaseCopyBox } from '@components/base/CopyBox';

export const CopyBox = (props: any) => {
  const { value, item, dataKey } = props;
  const val: string = getProperty(item, dataKey) ?? value;
  return (
    <div className="flex items-center gap-x-2">
      {!!val && (
        <BaseCopyBox type="text" copyText={val}>
          <p>{val}</p>
        </BaseCopyBox>
      )}
    </div>
  );
};

export default CopyBox;
