import { Action, ActionType } from '@store/interfaces/report/share';

const getLateItemState = (payload: any, forceCache = true): Action => {
  return { type: ActionType.GET_LATE_ITEM_STATE_REQUEST, payload, forceCache };
};
const getLateItemStateCached = (): Action => {
  return { type: ActionType.GET_LATE_ITEM_STATE_CACHED };
};
const getLateItemStateSuccess = (payload: any): Action => {
  return { type: ActionType.GET_LATE_ITEM_STATE_REQUEST_SUCCESS, payload };
};
const getLateItemStateFailure = (error?: any): Action => {
  return { type: ActionType.GET_LATE_ITEM_STATE_REQUEST_FAILURE, error };
};

export const actions = {
  getLateItemState,
  getLateItemStateCached,
  getLateItemStateSuccess,
  getLateItemStateFailure,
};

const returns = {
  actions,
};

export default returns;
