import React from 'react';
import { NavLink } from 'react-router-dom';

import qs from 'qs';

export interface ListItemInterface {
  label: string;
  to: string;
  query?: any;
  badge?: number;
  badgeClass?: string;
  permission?: string;
}

function ListItem(props: ListItemInterface) {
  const { label, to, query, badge, badgeClass } = props;
  const queryParam = qs.stringify(query || {});
  const url = `${to}${queryParam ? '?' : ''}${queryParam}`;
  return (
    <NavLink className="flex items-center justify-between py-3 pl-6" to={url}>
      <span className="text-gray-500 text-normal dark:text-gray-dark-500">{label}</span>
      {badge !== undefined && (
        <div className={`grid place-items-center rounded w-[18px] h-[18px] ${badgeClass}`}>
          <p className="font-medium text-gray-1100 text-[11px] leading-[11px]">{badge}</p>
        </div>
      )}
    </NavLink>
  );
}

export default ListItem;
