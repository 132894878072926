import React from 'react';

import { twMerge } from 'tailwind-merge';

import { IColumnType, RowDataPayload } from './Table';
import TableCell from './TableCell';

interface TableRowProps {
  items: RowDataPayload[];
  borderClass?: string;
  cellClass?: string;
  className?: string;
  columns: IColumnType[];
}

export const TableRow: React.FC<TableRowProps> = ({ items, className, cellClass = '', borderClass = '', columns }) => {
  const classes = twMerge(cellClass, borderClass);
  return (
    <>
      {items.map((item, idx: number) => (
        <tr className={className || ''} key={`table-body-${idx}`}>
          {columns.map((column, columnIndex) => (
            <TableCell className={classes} key={`table-row-cell-${columnIndex}`} item={item} column={column} />
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableRow;
