import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/returns/request-item';
import { Action, ActionType } from '@store/interfaces/returns/request-item';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getReturnRequest({ payload }: Action) {
  try {
    const params: any = { ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnItems.bind(magentoService), params);
    yield put(actions.getReturnsRequestItemSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardOrdersError());
  } catch (error: any) {
    yield put(actions.getReturnsRequestItemFailure(error?.message));
  }
}

function* returnRequestSaga() {
  yield takeLatest(ActionType.GET_RETURNS_REQUEST, getReturnRequest);
}
export default returnRequestSaga;
