import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthGuard = ({ redirectPath = '/login', children }: any) => {
  const user = useSelector((state: any) => state.auth);
  const location = useLocation();

  if (location.pathname === redirectPath) {
    throw new Error('Loop redirect path');
  }
  if (!user || !user.loggedIn) {
    try {
      localStorage.setItem('login-context', JSON.stringify(location));
    } catch (_) {}
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }
  return children ? children : <Outlet />;
};

export default AuthGuard;
