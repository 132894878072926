import { Action, ActionType } from '@store/interfaces/share';

const getUsers = (): Action => {
  return { type: ActionType.GET_USERS_REQUEST };
};
const getUsersSuccess = (payload: any): Action => {
  return { type: ActionType.GET_USERS_REQUEST_SUCCESS, payload };
};
const getUsersFailure = (error?: any): Action => {
  return { type: ActionType.GET_USERS_REQUEST_FAILURE, error };
};

export const actions = {
  getUsers,
  getUsersSuccess,
  getUsersFailure,
};

const users = {
  actions,
};

export default users;
