import { all } from 'redux-saga/effects';

import globalSaga from './global';

function* share(): any {
  yield all([
    globalSaga(),
    // other
  ]);
}

export default share;
