// eslint-disable-next-line
import { combineReducers } from 'redux';

import dashboard from './dashboard';
import dashboardDetail from './dashboard-detail';
import packageOrder from './package-order';
import packageOrderWaitCarrierShip from './packages-wait-carrier-ship';
import packages from './packages';
import shipoutWaiting from './shipout-waiting';
import shippingServiceDetail from './shipping-service-detail';
import packageOrderShipped from './package-order-shipped';

const returnsReducer: any = combineReducers<any>({
  dashboard,
  dashboardDetail,
  packageOrder,
  packageOrderWaitCarrierShip,
  packages,
  shipoutWaiting,
  shippingServiceDetail,
  packageOrderShipped,
});

export default returnsReducer;
