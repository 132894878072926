import { strings } from '@helpers';

export const BASE_API_ENDPOINT = process.env.REACT_APP_BASE_BACKEND_API_URI;

export const GLAMIRA_API_ENDPOINT = process.env.REACT_APP_GLAMIRA_API_URI;

export const APP_VERSION = process.env.REACT_APP_VERSION;

export const USER_KEY = 'user';
export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const OAUTH_GLAMIRA_AUTHORIZE_URL = process.env.REACT_APP_OAUTH_GLAMIRA_AUTHORIZE_URL || '';
export const OAUTH_GLAMIRA_CLIENT_ID = process.env.REACT_APP_OAUTH_GLAMIRA_CLIENT_ID || '';
export const OAUTH_REDIRECT_URI = process.env.REACT_APP_OAUTH_REDIRECT_URI || '';

export const oauth: any = {
  glamira: {
    client_id: OAUTH_GLAMIRA_CLIENT_ID,
    authorize_url: OAUTH_GLAMIRA_AUTHORIZE_URL,
    redirect_uri: OAUTH_REDIRECT_URI,
  },
};

let publicUrl = strings.rtrim(process.env.PUBLIC_URL || '', '/');
if (publicUrl.match(/^http(s)?:\/\//g) === null) {
  publicUrl = strings.rtrim(`${window.location.protocol}//${window.location.host}/${strings.ltrim(publicUrl, '/')}`, '/');
}

export const PUBLIC_URL = publicUrl;

export const REDUX_ENCRYPT_KEY = process.env.REACT_APP_REDUX_ENCRYPT_KEY || `my-super-secret-key_${APP_VERSION}`;

export const USE_API_MAGENTO_MOCK_DATA = Boolean(process.env.USE_API_MAGENTO_MOCK_DATA || false);

export const STONE_SYNTHETICS: { [key: string]: string } = {
  labdiamond: 'Moissanite',
  labpinkdiamond: 'Pink Diamond (Lab Created)',
  labbrowndiamond: 'Brown Diamond (Lab Created)',
  labbluediamond: 'Blue Diamond (Lab Created)',
  labyellowdiamond: 'Yellow Diamond (Lab Created)',
  labgreendiamond: 'Green Diamond (Lab Created)',
  labsapphire: 'Sapphire (Lab Created)',
  labruby: 'Ruby (Lab Created)',
  labemerald: 'Emerald (Lab Created)',
  labmorganite: 'Morganite (Lab Created)',
  labkunzite: 'Kunzite (Lab Created)',
  labrubellite: 'Rubellite (Lab Created)',
  'labsultan-stone': 'Sultan Stone (Lab Created)',
  'paraiba-tourmaline': 'Paraiba Tourmaline (Lab Created)',
};

export const STONE_SHAPES: { [key: string]: string } = {
  '1': 'Round',
  '2': 'Baguette',
  '3': 'Cabochon Round',
  '4': 'Cabochon Oval',
  '5': 'Cabochon',
  '6': 'Cushion',
  '7': 'Emerald',
  '8': 'Heart',
  '9': 'Oval',
  '10': 'Pear',
  '11': 'Princess',
  '12': 'Radiant',
  '13': 'Marquise',
  '14': 'Triangle',
  '15': 'Trillion',
  '16': 'Cabachon Cushion',
  '17': 'Cabachon Pear',
  '18': 'Asscher Cut',
};

export const STONE_CUT: { [key: string]: string } = {
  '1': 'Fair',
  '2': 'Good',
  '3': 'Very Good',
  '4': 'Excellent',
  '5': 'Step Cut',
  '6': 'Brilliant Cut',
  '7': 'Modified Brilliant Cut',
  '8': 'Ideal',
  '9': 'Poor',
};

export const SHARE_DATA_VERSION = 3;

export const DATE_FORMAT = 'yyyy/MM/dd';
export const DATE_TIME_FORMAT = 'HH:mm dd/MM/yyyy';
export const DATE_PICKER_FORMAT = 'YYYY/MM/DD';
