import { SHARE_DATA_VERSION } from '@constants';

export * from './date';
export * as strings from './strings';

export function pxToRem(value: number): string {
  return `${value / 16}rem`;
}

export function required<T>(value: T | undefined): T {
  if (!value) {
    throw new Error('value is required');
  }

  return value;
}

export const isFunc = (obj: any) => (obj && Object.prototype.toString.call(obj) === '[object Function]') || typeof obj == 'function' || false;

export const validateCache = (obj: any, expiredTime?: number) => {
  const ttl = 30 * 24 * 60 * 60 * 1000;
  if (typeof expiredTime === 'undefined') {
    expiredTime = new Date().getTime() - ttl;
  }
  if (!obj) {
    return false;
  }
  if (!obj.version || obj.version != SHARE_DATA_VERSION) {
    return false;
  }
  if (!obj.updatedAt || obj.updatedAt < expiredTime) {
    return false;
  }
  return true;
};
