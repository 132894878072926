// eslint-disable-next-line
import { all } from 'redux-saga/effects';

import dashboardSaga from './dashboard';
import dashboardDetailSaga from './dashboard-detail';
import packageOrderSaga from './package-order';
import packageWaitCarrierShip from './packages-wait-carrier-ship';
import packagesSaga from './packages';
import shipoutWaitingSaga from './shipout-waiting';
import shippingServiceDetailSaga from './shipping-service-detail';
import pullTrackingInfoSaga from './pull-tracking-info';
import packageOrderShippedSaga from './package-order-shipped';

function* aftership(): any {
  yield all([
    dashboardSaga(),
    dashboardDetailSaga(),
    packageOrderSaga(),
    packageWaitCarrierShip(),
    packagesSaga(),
    shipoutWaitingSaga(),
    shippingServiceDetailSaga(),
    pullTrackingInfoSaga(),
    packageOrderShippedSaga(),
    // other watch
  ]);
}

export default aftership;
