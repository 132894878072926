import React from 'react';

export function Spinner(props: any) {
  const { size = 8, color = 'gray' } = props;
  let bw = Math.floor(size / 2) || 2;
  if (size < 4) {
    bw = 2;
  }
  if (bw > 4) {
    bw = 4;
  }
  const borderColor: any = {
    gray: 'border-dark-neutral-bg dark:border-neutral-bg',
    white: 'border-neutral-bg dark:border-dark-neutral-bg',
  };
  return (
    <div
      className={`inline-block h-${size} w-${size} align-middle animate-spin rounded-full border-${bw} border-solid border-current ${
        borderColor[color] || ''
      } border-r-transparent dark:border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)] dark:![clip:rect(255,255,255,0)]">
        Loading...
      </span>
    </div>
  );
}

export default Spinner;
