import { Action as AnyAction } from 'redux';

export interface CounterState {
  readonly isLoading: boolean;
  readonly errorMessage: string;
  readonly count: number;
}

export interface State {
  readonly counter: CounterState;
}

export type Meta = null | { [key: string]: any };
export interface FSA<Type extends string, Payload = null> extends AnyAction {
  type: Type;
  payload?: Payload;
  error?: boolean;
  meta?: Meta;
}
export enum ActionType {
  increment = 'counter/INCREMENT',
  decrement = 'counter/DECREMENT',
}
export enum AsyncActionType {
  incrementStarted = 'counter/INCREMENT_ASYNC_STARTED',
  incrementDone = 'counter/INCREMENT_ASYNC_DONE',
  incrementFailed = 'counter/INCREMENT_ASYNC_FAILED',
  decrementStarted = 'counter/DECREMENT_ASYNC_STARTED',
  decrementDone = 'counter/DECREMENT_ASYNC_DONE',
  decrementFailed = 'counter/DECREMENT_ASYNC_FAILED',
}

export type Action = FSA<ActionType.increment> | FSA<ActionType.decrement>;

export type AsyncAction =
  | FSA<AsyncActionType.incrementStarted>
  | FSA<AsyncActionType.incrementDone>
  | FSA<AsyncActionType.incrementFailed>
  | FSA<AsyncActionType.decrementStarted>
  | FSA<AsyncActionType.decrementDone>
  | FSA<AsyncActionType.decrementFailed>;
