import formatDateFn from 'date-fns/format';
import parseDateFn from 'date-fns/parse';
import { formatInTimeZone, toDate } from 'date-fns-tz';

const DATE_TIME_FORMAT = /^\d{4}(-\d\d(-\d\d(\s+\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

/**
 * RegExp to test a string for a ISO 8601 Date spec
 *  YYYY
 *  YYYY-MM
 *  YYYY-MM-DD
 *  YYYY-MM-DDThh:mmTZD
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
const ISO_8601 = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;

/**
 * RegExp to test a string for a full ISO 8601 Date
 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
 *  YYYY-MM-DDThh:mm:ss
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

/**
 *
 * @param dateStr
 * @param formatString
 * @returns
 */
export const parseDate = (dateStr: any, formatString?: string, fromTimezone: any = 'UTC') => {
  if (!dateStr) {
    return null;
  }
  if (dateStr instanceof Date) {
    return dateStr;
  }
  if (!!formatString && (typeof formatString === 'string' || (formatString as any) instanceof String)) {
    return parseDateFn(dateStr as string, formatString, new Date());
  } else if (typeof dateStr === 'string' || dateStr instanceof String) {
    const str: string = dateStr as string;
    if (ISO_8601_FULL.test(str) || ISO_8601.test(str)) {
      if (fromTimezone) {
        return toDate(str.replace(' ', 'T'), { timeZone: fromTimezone });
      }
      return new Date(str);
    } else if (DATE_TIME_FORMAT.test(str)) {
      // convert to js date time yyyy-mm-dd\THH:mm:ss
      if (fromTimezone) {
        return toDate(str.replace(' ', 'T'), { timeZone: fromTimezone });
      }
      return parseDateFn(str, 'yyyy-MM-dd HH:mm:ss', new Date());
    }
  } else if (!isNaN(Number(dateStr)) && Number.isInteger(dateStr)) {
    return new Date(Number(dateStr));
  }
  throw new Error('Invalid Date');
};

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d.getTime());
};

/**
 *
 * @param dateStr
 * @param formatString
 * @returns string
 */
export const formatDate = (dateStr: any, formatString = 'dd/MM/yyyy', timezone?: string, fromTimezone = 'UTC', defaultText = '') => {
  let date = null;
  if (!dateStr || dateStr == null) {
    return defaultText;
  }
  try {
    date = parseDate(dateStr, undefined, fromTimezone);
  } catch (e) {
    return 'Invalid Date';
  }
  if (date && isValidDate(date)) {
    if (timezone) {
      return formatInTimeZone(date, timezone, formatString);
    }
    return formatDateFn(date, formatString);
  }
  return defaultText;
};

/**
 *
 * @param dateStr
 * @param formatString
 * @returns string
 */
export const formatDatetime = (dateStr: any, formatString = 'dd/MM/yyyy HH:mm:ss', timezone?: string, fromTimezone = 'UTC') => {
  return formatDate(dateStr, formatString, timezone, fromTimezone);
};
