import React from 'react';

import { twMerge } from 'tailwind-merge';

import Select, { SelectInterface } from '../Select';

export interface SelectFieldInterface extends SelectInterface {
  label?: string | React.ReactNode;
  labelClassName?: string;
  error?: string | React.ReactNode;
  errorClassName?: string;
  containerClassName?: string;
  required?: any;
}

const SelectField: React.ForwardRefRenderFunction<HTMLSelectElement, SelectFieldInterface & React.SelectHTMLAttributes<HTMLSelectElement>> = (
  { label, error, labelClassName, errorClassName, containerClassName, required = false, ...props },
  ref,
) => {
  const { size } = props;
  const textSize = size ? `text-${size}` : '';
  const labelClasses = twMerge('label-text font-medium dark:text-gray-dark-1100', textSize, labelClassName || '');
  const errorClasses = twMerge('mt-1 text-red italic text-sm', textSize, errorClassName || '');
  const containerClasses = twMerge('form-control', containerClassName || '');
  return (
    <div className={containerClasses}>
      {label && (
        <label className="label">
          <span className={labelClasses}>{required ? label + ' *' : label}</span>
        </label>
      )}
      <Select {...props} ref={ref} />
      {error && <p className={errorClasses}>{error}</p>}
    </div>
  );
};

export default React.forwardRef(SelectField);
