export enum ActionType {
  GET_DASHBOARD_SHIPMENT_DETAIL = `shipments/dashboard-items/GET_DASHBOARD_SHIPMENT_DETAIL`,
  GET_DASHBOARD_SHIPMENT_DETAIL_SUCCESS = `shipments/dashboard-items/GET_DASHBOARD_SHIPMENT_DETAIL_SUCCESS`,
  GET_DASHBOARD_SHIPMENT_DETAIL_FAILURE = `shipments/dashboard-items/GET_DASHBOARD_SHIPMENT_DETAIL_FAILURE`,

  CLEAR_MESSAGE_ERROR = `shipments/dashboard-items/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `shipments/dashboard-items/CLEAR_STATE`,
}

export type Action = any;
