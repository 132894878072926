import { call, cancelled, delay, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

import { ObjectInterface } from '@interfaces';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/user/users';
import { Action, ActionType } from '@store/interfaces/user/users';

interface AnyResponseInterface {
  [key: string]: any;
}

function* requestUsers({ payload }: Action): any {
  delay(100);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const params: ObjectInterface = { ...(payload || {}) };
    const options: ObjectInterface = { cancelToken: cancelTokenSource.token };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getMembers.bind(magentoService), params, options);
    yield put(actions.getUsersSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getUsersFailure(error?.message));
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      cancelTokenSource.cancel();
    }
  }
}

function* requestSaga() {
  yield takeLatest(ActionType.GET_ITEMS_REQUEST, requestUsers);
}

export default requestSaga;
