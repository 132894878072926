import React from 'react';
function Links() {
  return (
    <div className="flex items-center gap-[15px]">
      <a className="transition-colors duration-300 hover:text-color-brands" href="#">
        About
      </a>
      <a className="transition-colors duration-300 hover:text-color-brands" href="#">
        Careers
      </a>
      <a className="transition-colors duration-300 hover:text-color-brands" href="#">
        Policy
      </a>
      <a className="transition-colors duration-300 hover:text-color-brands" href="#">
        Contact
      </a>
    </div>
  );
}

export default Links;
