// eslint-disable-next-line
import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';
import { actions } from '@store/actions/shipments/package-order-shipped';
import { Action, ActionType } from '@store/interfaces/shipments/package-order-shipped';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getShipmentPackagesRequest({ payload }: Action) {
  try {
    const params: any = { ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.fetchShipmentPackageOrderShipped.bind(magentoService), params);
    yield put(actions.getPackagesSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getPackagesFailure(error?.message));
  }
}

function* shipmentPackagesSaga() {
  yield takeLatest(ActionType.GET_SHIPMENT_PACKAGE_ORDER_SHIPPED_REQUEST, getShipmentPackagesRequest);
}
export default shipmentPackagesSaga;
