import { SagaIterator } from 'redux-saga';
import { cancelled, delay, put, takeEvery } from 'redux-saga/effects';

import { asyncActions } from '@store/actions/counter';
import { ActionType } from '@store/interfaces/counter';

export function* incrementAsyncWorker(): SagaIterator {
  yield put(asyncActions.incrementStarted());
  try {
    yield delay(1000);
    if (Math.random() > 0.8) {
      throw new Error('Random: 0.8');
    }
    yield put(asyncActions.incrementDone());
  } catch (e) {
    yield put(asyncActions.incrementFailed());
  } finally {
    if (yield cancelled()) {
      yield put(asyncActions.incrementFailed());
    }
  }
}

export function* decrementAsyncWorker(): SagaIterator {
  yield put(asyncActions.decrementStarted());
  try {
    yield delay(1000);
    if (Math.random() > 0.8) {
      throw new Error('Random: 0.8');
    }
    yield put(asyncActions.decrementDone());
  } catch {
    yield put(asyncActions.decrementFailed());
  } finally {
    if (yield cancelled()) {
      yield put(asyncActions.decrementFailed());
    }
  }
}

function* counter() {
  yield takeEvery(ActionType.increment, incrementAsyncWorker);
  yield takeEvery(ActionType.decrement, decrementAsyncWorker);
}

export default counter;
