import { call, put, takeLatest } from 'redux-saga/effects';

import { ObjectInterface } from '@interfaces';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/returns/dashboard-detail';
import { Action, ActionType } from '@store/interfaces/returns/dashboard-detail';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getReturnRequest({ payload }: Action) {
  try {
    const params: ObjectInterface = { ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getDashboardReturnDetail.bind(magentoService), params);
    yield put(actions.getReturnsDetailItemsSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardOrdersError());
  } catch (error: any) {
    yield put(actions.getReturnsDetailItemsFailure(error?.message));
  }
}

function* returnDashboardDetailSaga() {
  yield takeLatest(ActionType.GET_DASHBOARD_RETURNS_DETAIL, getReturnRequest);
}
export default returnDashboardDetailSaga;
