import { MutableRefObject, useEffect, useRef, useState } from 'react';

/**
 *
 * const [hoverRef, isHovered] = useHover();
 * return <div ref={hoverRef}>{isHovered ? "😁" : "☹️"}</div>;
 */
export function useHover<T>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false);

  const ref: any = useRef<T | null>(null);

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(
    () => {
      const node: any = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          if (!node) {
            return;
          }
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    // eslint-disable-next-line
    [ref.current], // Recall only if ref changes
  );

  return [ref, value];
}

export default useHover;
