import React, { useEffect, useState } from 'react';
import BaseDatePicker from 'react-tailwindcss-datepicker';

import { twMerge } from 'tailwind-merge';

export interface RangeDatePickerInterface {
  startDate?: Date;
  endDate?: Date;
  onChange?: (value: any) => void;
  className?: string;
}

export const RangeDatePicker: React.FC<RangeDatePickerInterface & any> = ({ defaultValue, value, onChange, className, ...props }) => {
  const [defaultStartDate, defaultEndDate] = defaultValue || [];
  const [val, setVal] = useState<any>({ startDate: defaultStartDate, endDate: defaultEndDate });
  const handleValueChange = (newValue: any) => {
    const { startDate, endDate } = newValue;
    const value = startDate || endDate ? [startDate, endDate] : undefined;
    setVal(newValue);
    onChange && onChange(value);
  };
  const [startDate, endDate] = value || [];
  useEffect(() => {
    setVal((oldVal: any) => {
      return {
        ...oldVal,
        startDate,
        endDate,
      };
    });
  }, [startDate, endDate]);
  const classes = twMerge('datepicker-wrap', String(className || ''));
  return (
    <div className={classes}>
      <BaseDatePicker
        primaryColor={'blue'}
        inputClassName="input input-bordered focus:outline-none dark:text-gray-dark-1400 rounded w-full"
        displayFormat={'YYYY/MM/DD'}
        i18n="en"
        value={val}
        onChange={handleValueChange}
        {...props}
      />
    </div>
  );
};

export default RangeDatePicker;
