import { call, delay, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/shipments/dashboard';
import { Action, ActionType } from '@store/interfaces/shipments/dashboard';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getStatsPreShipment({ payload }: Action) {
  try {
    const params: any = { type: 'order-pre-shipment', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getDashboardShipment, params);
    yield put(actions.getPreShipmentDashboardSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getPreShipmentDashboardFailure(error?.message));
    yield delay(500);
    yield put(actions.clearMessagePreShipmentDashboardError());
  }
}

function* getStatsDispatcher({ payload }: Action) {
  try {
    const params: any = { type: 'dispatcher', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getDashboardShipment, params);
    yield put(actions.getDispatcherDashboardSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getDispatcherDashboardFailure(error?.message));
    yield delay(500);
    yield put(actions.clearMessageDispatcherDashboardError());
  }
}

function* dashboardSaga() {
  yield takeLatest(ActionType.GET_PRE_SHIPMENT_DASHBOARD_REQUEST, getStatsPreShipment);
  yield takeLatest(ActionType.GET_DISPATCHER_DASHBOARD_REQUEST, getStatsDispatcher);
}

export default dashboardSaga;
