import { Action, ActionType } from '@store/interfaces/share';

import country from './country';
import dispatcher from './dispatcher';
import allStatusLabel from './status-label';
import store from './store';
import users from './users';

const getShareData = (error?: any): Action => {
  return { type: ActionType.GET_SHARE_DATA_REQUEST, error };
};

export const actions = {
  store: store.actions,
  country: country.actions,
  dispatcher: dispatcher.actions,
  all_status_label: allStatusLabel.actions,
  users: users.actions,
  getShareData,
};

export const share = {
  store,
  country,
  users,
  all_status_label: allStatusLabel,
  // other
  actions,
};

export default share;
