export enum ActionType {
  GET_DASHBOARD_RETURNS_DETAIL = `returns/dashboard-items/GET_DASHBOARD_RETURNS_DETAIL`,
  GET_DASHBOARD_RETURNS_DETAIL_SUCCESS = `returns/dashboard-items/GET_DASHBOARD_RETURNS_DETAIL_SUCCESS`,
  GET_DASHBOARD_RETURNS_DETAIL_FAILURE = `returns/dashboard-items/GET_DASHBOARD_RETURNS_DETAIL_FAILURE`,

  CLEAR_MESSAGE_ERROR = `returns/dashboard-items/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `returns/dashboard-items/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
