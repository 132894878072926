import { Action, ActionType } from '@store/interfaces/share';

const getAllStore = (): Action => {
  return { type: ActionType.GET_ALL_STORE_REQUEST };
};
const getAllStoreSuccess = (payload: any): Action => {
  return { type: ActionType.GET_ALL_STORE_SUCCESS, payload };
};
const getAllStoreFailure = (error?: any): Action => {
  return { type: ActionType.GET_ALL_STORE_FAILURE, error };
};

const clearShareData = (): Action => {
  return { type: ActionType.CLEAR_SHARE_DATA };
};

export const actions = {
  getAllStore,
  getAllStoreSuccess,
  getAllStoreFailure,

  clearShareData,
};

const store = {
  actions,
};

export default store;
