import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import qs from 'qs';

import { ObjectInterface } from '@interfaces';

// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { BASE_API_ENDPOINT } from '@constants';

let id = 0;
const clients: { [key: string]: { client: AxiosInstance; stateMapFn?: (state: any) => string } } = {};

export function registerStore(store: any) {
  store.subscribe(listener);

  function select(state: any) {
    return state.auth.accessToken;
  }

  function listener() {
    Object.keys(clients).forEach((clientId) => {
      const { client, stateMapFn } = clients[clientId];
      const token = stateMapFn ? stateMapFn(store.getState()) : select(store.getState());
      if (token) {
        client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        delete client.defaults.headers.common['Authorization'];
      }
    });
  }
}

export function subscribeAuthorizationHeader(client: AxiosInstance, clientId?: string, stateMapFn?: (state: any) => string) {
  const cid = clientId || `axios-${id++}`;
  if (typeof clients[cid] != 'undefined') {
    throw new Error('Client Id has instance');
  }
  clients[cid] = { client, stateMapFn };
  return cid;
}

export function unsubscribeAuthorizationHeader(clientId: string) {
  if (typeof clients[clientId] != 'undefined') {
    console.warn('Client Id not match');
  }
  delete clients[clientId];
}

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
export const getClient = (baseUrl?: string, defaultHeaders: any = {}) => {
  const options: CreateAxiosDefaults = {
    baseURL: baseUrl || BASE_API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
      ...defaultHeaders,
    },
    // adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, { enabledByDefault: false })),
  };

  const client = axios.create(options);

  // Add a request interceptor
  client.interceptors.request.use(
    (requestConfig) => requestConfig,
    (requestError) => {
      return Promise.reject(requestError);
    },
  );

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    },
  );

  return client;
};

class ApiClient {
  protected client;
  protected defaultQueryParams: ObjectInterface | null;

  constructor(baseUrl?: string, headers: any = {}, defaultQueryParams: ObjectInterface = {}) {
    this.client = getClient(baseUrl, headers);
    this.defaultQueryParams = defaultQueryParams;
  }

  applyDefaultQueryParams(url: string) {
    if (!this.defaultQueryParams || !Object.keys(this.defaultQueryParams).length) {
      return url;
    }
    let joinChar = '?';
    if (url.indexOf('?') >= 0) {
      joinChar = '&';
    }
    return url + joinChar + qs.stringify(this.defaultQueryParams);
  }

  subscribe(fn: (client: AxiosInstance) => void) {
    fn(this.client);
    return this;
  }

  get(url: string, conf: ObjectInterface = {}) {
    return this.client
      .get(this.applyDefaultQueryParams(url), conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  delete(url: string, conf: ObjectInterface = {}) {
    return this.client
      .delete(this.applyDefaultQueryParams(url), conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  head(url: string, conf: ObjectInterface = {}) {
    return this.client
      .head(this.applyDefaultQueryParams(url), conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  options(url: string, conf: ObjectInterface = {}) {
    return this.client
      .options(this.applyDefaultQueryParams(url), conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  post(url: string, data: ObjectInterface = {}, conf: ObjectInterface = {}) {
    return this.client
      .post(this.applyDefaultQueryParams(url), data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  put(url: string, data: ObjectInterface = {}, conf: ObjectInterface = {}) {
    return this.client
      .put(this.applyDefaultQueryParams(url), data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  patch(url: string, data: ObjectInterface = {}, conf: ObjectInterface = {}) {
    return this.client
      .patch(this.applyDefaultQueryParams(url), data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url: string, conf = {}) {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  delete(url: string, conf = {}) {
    return getClient()
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  head(url: string, conf = {}) {
    return getClient()
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  options(url: string, conf = {}) {
    return getClient()
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  post(url: string, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  put(url: string, data = {}, conf = {}) {
    return getClient()
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  patch(url: string, data = {}, conf = {}) {
    return getClient()
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
