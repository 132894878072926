import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { strings } from '@helpers';

import { Icon } from '@components/base';
import useFilterItemsPermission from '@hooks/useFilterItemsPermission';
import useHasPermission from '@hooks/useHasPermission';

import SubNav from './SubNav';
import { SubNavItemInterface } from './SubNavItem';

export interface NavInterface {
  label: string;
  id?: string;
  icon: string;
  to?: string;
  items?: SubNavItemInterface[];
  autoActive?: boolean;
  permission?: string | string[] | null;
}

function Nav(props: NavInterface) {
  const location = useLocation();
  const { label, id, icon, items, to, autoActive, permission } = props;
  const idx = id ? id : strings.slugify(`${icon}_${label}`);

  const isActive = useMemo(() => {
    let active = location.pathname === to;
    items &&
      items.forEach((item) => {
        active = active || location.pathname === item.to;
      });
    return active;
  }, [items, to, location.pathname]);

  const menuItems = useFilterItemsPermission(items);

  const attrs: any = {};
  let WrapTag: any = 'div' as keyof JSX.IntrinsicElements;
  if (to) {
    WrapTag = Link;
    attrs.to = to;
  }
  const hasPermission = useHasPermission(String(permission || ''));
  if (!hasPermission || (items?.length && !menuItems.length)) {
    return <></>;
  }
  return (
    <WrapTag className={`sidemenu-item rounded-xl relative ${isActive ? 'active' : ''}`} {...attrs}>
      <input className="sr-only peer" type="checkbox" defaultChecked={isActive && autoActive} value={idx} name="sidemenu" id={idx} />
      <label
        className="flex items-center justify-between w-full cursor-pointer py-[17px] px-[21px] focus:outline-none peer-checked:border-transparent"
        htmlFor={idx}
      >
        <div className="flex items-center gap-[10px]">
          <Icon name={icon} />
          <span className="text-normal font-semibold text-gray-500 sidemenu-title dark:text-gray-dark-500">{label}</span>
        </div>
      </label>
      {menuItems.length > 0 && (
        <>
          <Icon
            className="absolute right-2 transition-all duration-150 caret-icon pointer-events-none peer-checked:rotate-180 top-[22px]"
            name="arrow-down"
            alt="caret icon"
          />
          <div className="hidden peer-checked:block">
            <SubNav items={items || []} />
          </div>
        </>
      )}
    </WrapTag>
  );
}

export default Nav;
