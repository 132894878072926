import React, { createContext, useEffect, useState } from 'react';

import usePrevious from '@hooks/usePrevious';

import baseTheme from './base';
import { applyTheme } from './utils';

export const ThemeContext = createContext({} as any);
export default ({ children }: any) => {
  const themeDetect =
    localStorage.getItem('theme') || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  const [theme, setTheme] = useState(themeDetect);

  const prevTheme = usePrevious({ theme });

  useEffect(() => {
    applyTheme(baseTheme);
  }, []);

  useEffect(() => {
    if (prevTheme?.theme) {
      document.documentElement.classList.remove(prevTheme?.theme);
    }
    document.documentElement.classList.add(theme);
    localStorage.setItem('theme', theme);
  }, [prevTheme?.theme, theme]);

  const defaultContext = {
    theme,
    setTheme,
  };
  return <ThemeContext.Provider value={defaultContext}>{children}</ThemeContext.Provider>;
};
