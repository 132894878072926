import { SHARE_DATA_VERSION } from '@constants';

import { Action, ActionType } from '@store/interfaces/report/share';

const initialState: any = { loading: {}, lateItemStatus: [], metadata: {} };

export default function returns(state = initialState, action: Action) {
  const { payload } = action;
  switch (action.type) {
    case ActionType.GET_LATE_ITEM_STATE_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          lateItemStatus: true,
        },
      };
    case ActionType.GET_LATE_ITEM_STATE_CACHED:
      return {
        ...state,
        loading: {
          ...state.loading,
          lateItemStatus: false,
        },
      };
    case ActionType.GET_LATE_ITEM_STATE_REQUEST_SUCCESS:
      return {
        ...state,
        lateItemStatus: payload,
        metadata: {
          ...state.metadata,
          lateItemStatus: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
        loading: {
          ...state.loading,
          lateItemStatus: false,
        },
      };
    default:
      return state;
  }
}
