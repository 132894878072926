import { Action, ActionType } from '@store/interfaces/returns/share';

const getReturnType = (): Action => {
  return { type: ActionType.GET_RETURN_TYPE_REQUEST };
};
const getReturnTypeSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURN_TYPE_SUCCESS, payload };
};
const getReturnTypeFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURN_TYPE_FAILURE, error };
};

const getReturnStatus = (): Action => {
  return { type: ActionType.GET_RETURN_STATUS_REQUEST };
};
const getReturnStatusSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURN_STATUS_SUCCESS, payload };
};
const getReturnStatusFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURN_STATUS_FAILURE, error };
};

const getReturnItemState = (): Action => {
  return { type: ActionType.GET_RETURN_ITEM_STATE_REQUEST };
};
const getReturnItemStateSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURN_ITEM_STATE_SUCCESS, payload };
};
const getReturnItemStateFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURN_ITEM_STATE_FAILURE, error };
};

const getReturnShareData = (): Action => {
  return { type: ActionType.GET_RETURN_SHARE_DATA_REQUEST };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getReturnType,
  getReturnTypeSuccess,
  getReturnTypeFailure,

  getReturnStatus,
  getReturnStatusSuccess,
  getReturnStatusFailure,

  getReturnItemState,
  getReturnItemStateSuccess,
  getReturnItemStateFailure,

  getReturnShareData,

  clearMessageError,
};

const returns = {
  actions,
};

export default returns;
