import { all } from 'redux-saga/effects';

import orderSaga from './order';
import ordersSaga from './orders';

function* aftership(): any {
  yield all([
    // other watch
    ordersSaga(),
    orderSaga(),
  ]);
}

export default aftership;
