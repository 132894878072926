import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

interface IFileDto {
  base64_encoded_data: string;
  type: 'application/pdf' | string;
  name: string;
}

export function exportToXlsx(data: any, filename?: string) {
  const ext = '.xlsx';
  const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const ws = XLSX.utils.json_to_sheet(data as any[]);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const result = new Blob([excelBuffer], { type: mimetype });
  FileSaver.saveAs(result, `${filename || 'data'}${ext}`);
}

export function saveBlobFile(data: any, mimetype: string, filename: string) {
  const result = new Blob([data], { type: mimetype });
  FileSaver.saveAs(result, filename);
}

export function downloadFile({ base64_encoded_data, type, name }: IFileDto) {
  if (!base64_encoded_data || !type) {
    throw new Error('Invalid file data');
  }
  const byteString = window.atob(base64_encoded_data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type });
  const filename = name ?? `download.${type.split('/').pop()}`;
  FileSaver.saveAs(blob, filename);
}
export interface FileInfoInterface {
  name: string;
  type?: string;
  size: number;
  base64: string | ArrayBuffer;
  file: File;
}

export const base64ToBlob = (base64Str: string, contentType: string, sliceSize = 512) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(base64Str);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getFileInfo: (file: File) => Promise<FileInfoInterface> = (file: File) => {
  return new Promise<FileInfoInterface>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve({
          name: file.name,
          type: file.type,
          size: file.size,
          base64: reader.result,
          file: file,
        });
      } else {
        reject('File Empty');
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
