// eslint-disable-next-line
import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';
import { actions } from '@store/actions/shipments/packages-wait-carrier-ship';
import { Action, ActionType } from '@store/interfaces/shipments/packages-wait-carrier-ship';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getShipmentPackagesRequest({ payload }: Action) {
  try {
    const params: any = { ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.fetchShipmentPackagesWaitCarrierShip.bind(magentoService), params);
    yield put(actions.getPackagesSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardOrdersError());
  } catch (error: any) {
    yield put(actions.getPackagesFailure(error?.message));
  }
}

function* shipmentPackagesSaga() {
  yield takeLatest(ActionType.GET_SHIPMENT_PACKAGE_WAIT_CARRIER_SHIP_REQUEST, getShipmentPackagesRequest);
}
export default shipmentPackagesSaga;
