import { call, cancelled, delay, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

import { ObjectInterface } from '@interfaces';

import { validateCache } from '@helpers';

import magentoService from '@services/magento.service';

import { actions as reportShareActions } from '@store/actions/report/share';
import { Action } from '@store/interfaces/report/latest-order';
import { ActionType as ReportShareActionType } from '@store/interfaces/report/share';

interface AnyResponseInterface {
  [key: string]: any;
}

const getShareMetaData = (state: any) => state?.report?.share?.metadata || {};

function* requestLateItemState({ payload, forceCache = true }: Action): any {
  if (!forceCache) {
    const ttl = 30 * 24 * 60 * 60 * 1000;
    const expiredTime = new Date().getTime() - ttl;
    const state: AnyResponseInterface = yield select(getShareMetaData);
    if (validateCache(state.lateItemStatus, expiredTime)) {
      yield put(reportShareActions.getLateItemStateCached());
      return;
    }
  }
  delay(100);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const params: ObjectInterface = { ...(payload || {}) };
    const options: ObjectInterface = { cancelToken: cancelTokenSource.token };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getLateItemState.bind(magentoService), params, options);
    yield put(reportShareActions.getLateItemStateSuccess(dataResponse));
  } catch (error: any) {
    yield put(reportShareActions.getLateItemStateFailure(error?.message));
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      cancelTokenSource.cancel();
    }
  }
}

function* requestSaga() {
  yield takeLatest(ReportShareActionType.GET_LATE_ITEM_STATE_REQUEST, requestLateItemState);
}
export default requestSaga;
