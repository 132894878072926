import { Action, ActionType } from '@store/interfaces/report/late-item-stats';

const getProductionStats = (payload: any): Action => {
  return { type: ActionType.GET_PRODUCTION_STATS_REQUEST, payload };
};
const getProductionStatsSuccess = (payload: any): Action => {
  return { type: ActionType.GET_PRODUCTION_STATS_SUCCESS, payload };
};
const getProductionStatsFailure = (error?: any): Action => {
  return { type: ActionType.GET_PRODUCTION_STATS_FAILURE, error };
};

const getShipmentStats = (payload: any): Action => {
  return { type: ActionType.GET_SHIPMENT_STATS_REQUEST, payload };
};
const getShipmentStatsSuccess = (payload: any): Action => {
  return { type: ActionType.GET_SHIPMENT_STATS_SUCCESS, payload };
};
const getShipmentStatsFailure = (error?: any): Action => {
  return { type: ActionType.GET_SHIPMENT_STATS_FAILURE, error };
};

const getReturnStats = (payload: any): Action => {
  return { type: ActionType.GET_RETURN_STATS_REQUEST, payload };
};
const getReturnStatsSuccess = (payload: any): Action => {
  return { type: ActionType.GET_RETURN_STATS_SUCCESS, payload };
};
const getReturnStatsFailure = (error?: any): Action => {
  return { type: ActionType.GET_RETURN_STATS_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getProductionStats,
  getProductionStatsSuccess,
  getProductionStatsFailure,

  getShipmentStats,
  getShipmentStatsSuccess,
  getShipmentStatsFailure,

  getReturnStats,
  getReturnStatsSuccess,
  getReturnStatsFailure,

  clearMessageError,
};

const stats = {
  actions,
};

export default stats;
