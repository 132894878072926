export enum ActionType {
  GET_ITEMS_REQUEST = `user/users/GET_ITEMS_REQUEST`,
  GET_ITEMS_REQUEST_SUCCESS = `user/users/GET_ITEMS_REQUEST_SUCCESS`,
  GET_ITEMS_REQUEST_FAILURE = `user/users/GET_ITEMS_REQUEST_FAILURE`,

  CLEAR_MESSAGE_ERROR = `user/users/CLEAR_MESSAGE_ERROR`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
