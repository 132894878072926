import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Select } from '@components/base';

export interface PaginationInterface {
  onClickItem?: (page: number) => void;
  onPageSizeChange?: (pageSize: number | string, totalPage?: number) => void;
  page?: number;
  total: number;
  pageSize?: number;
  to?: string | any;
  pageParam?: string;
  pageSizeParam?: string;
  children?: React.ReactNode;
}

export interface PaginationItemInterface {
  label: string;
  active?: boolean;
  to?: string | any;
  onClick?: (item: PaginationItemInterface) => void;
}

export function Pagination({ children, onPageSizeChange, ...props }: PaginationInterface) {
  const { total, page = 1, pageSize = 20, onClickItem } = props;
  const perPageOptions = [10, 20, 30, 50, 100, 200].reduce((arr: any[], p) => {
    arr.push({
      value: p,
      label: p,
    });
    return arr;
  }, []);
  const { t } = useTranslation();
  const totalPage = useMemo(() => {
    return Math.ceil(total / pageSize);
  }, [total, pageSize]);
  const limit = window.innerWidth < 650 ? 3 : 5;
  const pages = useMemo(() => {
    let items: number[] = [];
    if (totalPage < limit) {
      items = Array.from({ length: totalPage }, (_x, i) => i + 1);
    } else {
      items = Array.from({ length: limit }, (_x, i) => {
        const pad = Math.floor(limit / 2);
        return i < pad ? page + i - pad : i == pad ? page : page + i - pad;
      });
      if (items[0] < 1) {
        const inc = 1 - items[0];
        items = items.map((x) => x + inc);
      } else if (items[limit - 1] > totalPage) {
        const dec = items[limit - 1] - totalPage;
        items = items.map((x) => x - dec);
      }
      if (items[0] > 1) {
        items[0] > 2 && items.unshift(-1);
        items.unshift(1);
      }
      if (items[items.length - 1] < totalPage) {
        items[items.length - 1] < totalPage - 1 && items.push(-1);
        items.push(totalPage);
      }
    }
    return items;
  }, [totalPage, page, limit]);
  const handlerItemClick = useCallback(
    (evt: React.MouseEvent) => {
      if (onClickItem && evt.currentTarget) {
        onClickItem(+(evt.currentTarget.getAttribute('data-page') || 0));
      }
    },
    [onClickItem],
  );
  const prevPageHandler = useCallback(
    (evt: React.MouseEvent) => {
      evt.preventDefault();
      if (page > 1 && onClickItem) {
        onClickItem(page - 1);
      }
    },
    [page, onClickItem],
  );
  const nextPageHandler = useCallback(
    (evt: React.MouseEvent) => {
      evt.preventDefault();
      if (page < totalPage && onClickItem) {
        onClickItem(page + 1);
      }
    },
    [page, totalPage, onClickItem],
  );
  const pageSizeHandler = useCallback(
    (evt: any) => {
      const pageSize: string | number = evt.currentTarget.value;
      if (onPageSizeChange && pageSize) {
        onPageSizeChange(pageSize, Math.ceil(total / Number(pageSize)));
      }
    },
    [onPageSizeChange, total],
  );
  const totalTxt: string = t('{{total}} records found', { total });
  const perPageTxt: string = t('per page');
  return (
    <>
      <div className="flex flex-row flex-wrap items-center justify-evenly">
        <div className="flex-1 mb-2 support-text dark:text-gray-dark-1100 whitespace-nowrap" dangerouslySetInnerHTML={{ __html: totalTxt }}></div>
        {onPageSizeChange && (
          <div className="flex flex-row items-center justify-end mb-2">
            <Select className="w-min min-w-[80px] overflow-visible" options={perPageOptions} value={pageSize} onChange={pageSizeHandler} />
            <span className="ml-1 dark:text-gray-dark-1100" dangerouslySetInnerHTML={{ __html: perPageTxt }}></span>
          </div>
        )}
        <div className="flex flex-row mb-2 items-center justify-center ml-2">
          <a
            className={`items-center justify-center border rounded-lg border-neutral px-[10px] py-[11px] dark:border-dark-neutral-border sm:flex ${
              page < 2 ? 'disabled' : ''
            }`}
            href="#"
            onClick={prevPageHandler}
          >
            <Icon name="arrow-left" alt="arrow left icon" />
            <span className="sr-only">Previous</span>
          </a>
          <div className="flex flex-row">
            {pages.map((i: number, idx: number) => {
              if (i <= 0) {
                return (
                  <span key={idx} className="leading-4 text-gray-1100 py-[11px] px-[13px] dark:text-gray-dark-1100">
                    ...
                  </span>
                );
              }
              let cls =
                'btn text-sm h-fit min-h-fit capitalize leading-4 border-1 border-neutral dark:border-dark-neutral-border bg-transparent font-semibold text-gray-1100 py-[11px] px-[13px] hover:text-white hover:bg-color-brands dark:text-gray-dark-1100 mx-1';
              if (i === page) {
                cls =
                  'btn text-sm h-fit min-h-fit capitalize leading-4 border-1 border-neutral dark:border-dark-neutral-border bg-color-brands font-semibold py-[11px] px-[13px] hover:bg-color-brands mx-1';
              }
              return (
                <button className={cls} key={idx} onClick={handlerItemClick} data-page={i}>
                  {i}
                </button>
              );
            })}
          </div>
          <a
            className={`items-center justify-center border rounded-lg border-neutral px-[10px] py-[11px] dark:border-dark-neutral-border sm:flex ${
              page > totalPage - 1 ? 'disabled' : ''
            }`}
            href="#"
            onClick={nextPageHandler}
          >
            <span className="sr-only">Next</span>
            <Icon name="arrow-right" alt="arrow right icon" />
          </a>
        </div>
      </div>
      {children ? children : <></>}
    </>
  );
}

export default Pagination;
