import React from 'react';

import { twMerge } from 'tailwind-merge';

import Input, { InputInterface } from '../Input';

export interface InputFieldInterface extends InputInterface {
  label?: string | React.ReactNode;
  labelClassName?: string;
  error?: string | React.ReactNode;
  errorClassName?: string;
  containerClassName?: string;
  required?: any;
}

const InputField: React.ForwardRefRenderFunction<HTMLInputElement, InputFieldInterface & React.InputHTMLAttributes<HTMLInputElement>> = (
  { label, error, labelClassName, errorClassName, containerClassName, required = false, ...props },
  ref,
) => {
  const { size } = props;
  const textSize = size ? `text-${size}` : '';
  const labelClasses = twMerge('label-text font-medium dark:text-gray-dark-1100', textSize, labelClassName || '');
  const errorClasses = twMerge('mt-1 text-red italic', textSize, errorClassName || '');
  const containerClasses = twMerge('form-control', containerClassName || '');
  return (
    <div className={containerClasses}>
      {label && (
        <label className="label">
          <span className={labelClasses}>{required ? label + ' *' : label}</span>
        </label>
      )}
      <Input {...props} ref={ref} />
      {error && <p className={errorClasses}>{error}</p>}
    </div>
  );
};

export default React.forwardRef(InputField);
