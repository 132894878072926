import { all } from 'redux-saga/effects';

import lateItemStatsSaga from './late-item-stats';
import latestOrderSaga from './latest-order';
import shareSaga from './share';
import shipmentOverdueDeliverySaga from './shipment-overdue-delivery';

function* returns(): any {
  yield all([
    shareSaga(),
    latestOrderSaga(),
    lateItemStatsSaga(),
    shipmentOverdueDeliverySaga(),
    // other watch
  ]);
}

export default returns;
