import { all } from 'redux-saga/effects';

import usersSaga from './users';

function* returns(): any {
  yield all([
    usersSaga(),
    // other watch
  ]);
}

export default returns;
