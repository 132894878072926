import { OrderInterface } from '@services/aftership.dto';

import { Action, ActionType } from '@store/interfaces/aftership/order';

const initialState: { loading: boolean; data?: OrderInterface } = { loading: true };

export default function order(state = initialState, action: Action) {
  // const { payload } = action;
  switch (action.type) {
    case ActionType.GET_ORDER_REQUEST:
      return {
        ...state,
        data: undefined,
        loading: true,
      };
    case ActionType.GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        message: action?.message,
      };
    case ActionType.GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    case ActionType.CLEAR_ORDER_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
