const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const copyText = (textToCopy: string) => {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;

    // Move textarea out of the viewport so it's not visible
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};
export const copyRichText = (html: string, txt: string, fn: () => void) => {
  try {
    if (isSafari) {
      const clipboardItem = new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' }),
        'text/plain': new Blob([txt], { type: 'text/plain' }),
      });
      navigator.clipboard.write([clipboardItem]).then(fn);
    } else {
      const listener = (ev: any) => {
        ev.preventDefault();
        ev.clipboardData.setData('text/html', html);
        ev.clipboardData.setData('text/plain', html);
        fn();
      };
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
    }
  } catch (err) {
    console.log(err);
  }
};
