import { MutableRefObject, useEffect, useState } from 'react';

/**
 * // Ref for the element that we want to detect whether on screen
 * const ref = useRef();
 * Call the hook passing in ref and root margin
 * // In this case it would only be considered onScreen if more ...
 * // ... than 300px of element is visible.
 * const onScreen = useOnScreen(ref, "-300px");
 *
 * @param ref
 * @param rootMargin
 * @returns
 */
export function useOnScreen<T extends Element>(ref: MutableRefObject<T>, rootMargin = '0px'): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const elm = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (elm) {
      observer.observe(ref.current);
    }
    return () => {
      if (elm) {
        observer.unobserve(elm);
      }
    };
    // eslint-disable-next-line
  }, [ref.current, rootMargin]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}
