import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ObjectInterface } from '@interfaces';

import TableCell from './TableCell';
import TableHead from './TableHead';
import TableRow from './TableRow';

export interface RowDataPayload extends ObjectInterface {}
export interface TableProps extends ObjectInterface {
  className?: string;
  borderClass?: string;
  cellBorderClass?: string;
  headWrapClass?: string;
  headCellBorderClass?: string;
  rowWrapClass?: string;
  rowCellClass?: string;
  options: TableOptionProps;
  items?: RowDataPayload[];
}

interface TableOptionProps {
  columns: IColumnType[];
}

export interface IColumnType extends ObjectInterface {
  key: string;
  title: string;
  sortable?: boolean;
  sorting?: 'asc' | 'desc' | '' | null;
  format?: (value: any, item?: any, dataKey?: string, column?: any) => string | null;
  headClass?: string;
  headCellClass?: string;
  cellClass?: string;
  cellComponent?: any;
  headComponent?: any;
  // for date/datetime format
  timezone?: string;
  formatString?: string;
}

export const Table: React.FC<TableProps> = ({
  borderClass = 'border-collapse border border-slate-300 border-neutral dark:border-dark-neutral-border',
  cellBorderClass = 'border border-slate-200 border-dashed border-neutral dark:border-dark-neutral-border ',
  headCellBorderClass = 'border border-slate-300  border-neutral dark:border-dark-neutral-border',
  className = 'w-full min-w-[900px] border-collapse mb-4',
  headWrapClass = 'bg-slate-50 dark:bg-slate-700 font-normal text-normal text-left text-slate-500 dark:text-slate-200',
  headCellClass = 'px-2 py-4',
  rowWrapClass = 'font-normal text-normal text-left text-slate-500 dark:text-slate-200',
  rowCellClass = 'p-2',
  options,
  items,
  ...props
}) => {
  const { columns } = options;
  const classes = twMerge(className, borderClass);
  return (
    <table className={classes}>
      <thead>
        <TableHead borderClass={headCellBorderClass} className={headWrapClass} cellClass={headCellClass} columns={columns} {...props} />
      </thead>
      <tbody>
        <TableRow borderClass={cellBorderClass} className={rowWrapClass} cellClass={rowCellClass} items={items || []} columns={columns} {...props} />
      </tbody>
    </table>
  );
};

export default Object.assign(Table, {
  Head: TableHead,
  Row: TableRow,
  Cell: TableCell,
});
