export enum ActionType {
  GET_RETURNS_REQUEST = `returns/request-item/GET_RETURNS_REQUEST`,
  GET_RETURNS_REQUEST_SUCCESS = `returns/request-item/GET_RETURNS_REQUEST_SUCCESS`,
  GET_RETURNS_REQUEST_FAILURE = `returns/request-item/GET_RETURNS_REQUEST_FAILURE`,

  CLEAR_MESSAGE_ERROR = `returns/request-item/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `returns/request-item/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
