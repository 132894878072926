import { combineReducers } from 'redux';

import aftership from '@store/reducers/aftership';
import auth from '@store/reducers/auth';
import counter from '@store/reducers/counter';
import report from '@store/reducers/report';
import returns from '@store/reducers/returns';
import share from '@store/reducers/share';
import shipments from '@store/reducers/shipments';
import user from '@store/reducers/user';

export const reducers = {
  auth,
  share,
  counter,
  aftership,
  shipments,
  returns,
  report,
  user,
};

export const createRootReducers = (applyReducers: (reducers: any) => any) => {
  return combineReducers(applyReducers(reducers));
};
