import { useContext } from 'react';

import { PageTitleContext } from '@layouts/context/PageTitleContext';

export const usePageTitle = (title: string | null = null): { setPageTitle: (title: string) => void; pageTitle: string | undefined } => {
  const { setPageTitle, pageTitle } = useContext(PageTitleContext);
  if (title !== null) {
    setPageTitle(title);
  }
  return {
    setPageTitle,
    pageTitle,
  };
};

export default usePageTitle;
