import { useSelector } from 'react-redux';

import { ObjectInterface } from '@interfaces';

import { matchPermission } from '@helpers/permission';

import ForbiddenError from '~/errors/Forbidden';

export const useValidatePermission = (id: string | string[]) => {
  const user = useSelector((state: any) => state.auth);
  const permissions: ObjectInterface = useSelector((state: any) => state.auth?.user?.permissions || {});
  const ids = Array.isArray(id) ? id.slice() : [id];
  const allowed = ids.reduce((res, id) => {
    if (!res) {
      return false;
    }
    return matchPermission(id, permissions);
  }, user && user.loggedIn);
  if (!allowed) {
    throw new ForbiddenError();
  }
};

export default useValidatePermission;
