import { call, cancelled, delay, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

import { ObjectInterface } from '@interfaces';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/report/late-item-stats';
import { Action, ActionType } from '@store/interfaces/report/late-item-stats';

interface AnyResponseInterface {
  [key: string]: any;
}

function* requestShipment({ payload }: Action): any {
  delay(100);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const params: ObjectInterface = { ...(payload || {}), type: 'shipment' };
    const options: ObjectInterface = { cancelToken: cancelTokenSource.token };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getStatsLateItemStatus.bind(magentoService), params, options);
    yield put(actions.getShipmentStatsSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getShipmentStatsFailure(error?.message));
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      cancelTokenSource.cancel();
    }
  }
}

function* requestReturn({ payload }: Action): any {
  delay(100);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const params: ObjectInterface = { ...(payload || {}), type: 'return' };
    const options: ObjectInterface = { cancelToken: cancelTokenSource.token };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getStatsLateItemStatus.bind(magentoService), params, options);
    yield put(actions.getReturnStatsSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getReturnStatsFailure(error?.message));
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      cancelTokenSource.cancel();
    }
  }
}

function* requestProduction({ payload }: Action): any {
  delay(100);
  const cancelTokenSource = axios.CancelToken.source();
  try {
    const params: ObjectInterface = { ...(payload || {}), type: 'production' };
    const options: ObjectInterface = { cancelToken: cancelTokenSource.token };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getStatsLateItemStatus.bind(magentoService), params, options);
    yield put(actions.getProductionStatsSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getProductionStatsFailure(error?.message));
  } finally {
    if (yield cancelled()) {
      console.log('cancel');
      cancelTokenSource.cancel();
    }
  }
}

function* requestSaga() {
  yield takeLatest(ActionType.GET_SHIPMENT_STATS_REQUEST, requestShipment);
  yield takeLatest(ActionType.GET_RETURN_STATS_REQUEST, requestReturn);
  yield takeLatest(ActionType.GET_PRODUCTION_STATS_REQUEST, requestProduction);
}
export default requestSaga;
