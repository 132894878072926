import React, { createContext, useState } from 'react';

export const PageTitleContext = createContext({} as any);

export default ({ children }: any) => {
  const [pageTitle, setPageTitle] = useState('Dashboard');

  const defaultContext = {
    pageTitle,
    setPageTitle,
  };
  return <PageTitleContext.Provider value={defaultContext}>{children}</PageTitleContext.Provider>;
};
