import React, { useEffect, useRef, useState } from 'react';

interface SVGIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  name: string;
  [key: string]: any;
}

interface UseDynamicSVGImportOptions {
  onCompleted?: (name: string, SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined) => void;
  onError?: (err: Error) => void;
}

export function useDynamicSVGImport(name: string, options: UseDynamicSVGImportOptions = {}) {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const { onCompleted, onError } = options;
  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        const { ReactComponent } = await import(`@assets/images/icons/${name}.svg`);
        if (ReactComponent) {
          ImportedIconRef.current = ReactComponent;
          onCompleted?.(name, ImportedIconRef.current);
        }
      } catch (err) {
        onError?.(err as Error);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name, onCompleted, onError]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
}

export const Icon: React.FC<SVGIconProps> = ({ name = 'messages', ...props }) => {
  const { width, height } = props;
  const { error, loading, SvgIcon } = useDynamicSVGImport(`icon-${name}`);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, [loading]);
  if (SvgIcon && loaded) {
    return <SvgIcon {...props} width={width} data-name={name} />;
  }
  const w = width || 1;
  const h = height || w;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`} width={w} height={h} data-name={name}>
      <rect width="10" height="10" fill={error ? '#ff0000' : '#cccccc'}></rect>
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontFamily="monospace" fontSize="12px" fill="#333333">
        {loading ? '...' : error ? error.message : '1x1'}
      </text>
    </svg>
  );
};

export default Icon;
