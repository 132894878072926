import { SagaIterator } from 'redux-saga';
import { call, cancelled, put, takeLatest } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import { ObjectInterface } from '@interfaces';

import authService from '@services/auth.service';

import { actions } from '@store/actions/auth';
import { ActionType } from '@store/interfaces/auth';

export function* tokenRequestAsyncWorker(payload: any): SagaIterator {
  try {
    if (payload.code) {
      const response: AxiosResponse = yield call(authService.loginByCode, payload?.code as string);
      const { access_token: accessToken, refresh_token: refreshToken, ...props } = response.data.data || {};
      yield put(actions.userInfoRequest({ accessToken, refreshToken, ...props }, true));
    } else {
      yield put(actions.loginFailure('The `code` has empty'));
    }
  } catch (ex: any) {
    yield put(actions.loginFailure(ex.message || ex.toString()));
  } finally {
    if (yield cancelled()) {
      yield put(actions.loginFailure('Cancelled'));
    }
  }
}

export function* loginRequestAsyncWorker(payload: any): SagaIterator {
  const {
    payload: { username, password },
  } = payload;
  try {
    if (username && password) {
      const response: AxiosResponse = yield call(authService.login, username as string, password as string);
      let res = response?.data?.data;
      if (typeof response === 'string') {
        res = {
          access_token: response,
          refresh_token: undefined,
        };
      }
      console.log('res', res);
      const { access_token: accessToken, refresh_token: refreshToken, ...props } = res || {};
      console.log(accessToken, refreshToken, props);
      yield put(actions.userInfoRequest({ accessToken, refreshToken, ...props }, true));
    } else {
      yield put(actions.loginFailure('Username, Password required'));
    }
  } catch (ex: any) {
    yield put(actions.loginFailure(ex.message || ex.toString()));
  } finally {
    if (yield cancelled()) {
      yield put(actions.loginFailure('Cancelled'));
    }
  }
}
export function* loadUserPermissionsWorker({ payload }: any): SagaIterator {
  try {
    const { user, accessToken } = payload;
    let userId = null;
    if (user && user.id) {
      userId = +user.id;
    }
    const res: AxiosResponse = yield call(authService.fetchUserPermissions, String(accessToken), userId);
    console.log(res);
    const permissions: ObjectInterface = (res?.data?.items || []).reduce((obj: ObjectInterface, item: any) => {
      const { resource_id } = item;
      obj[resource_id] = item;
      return obj;
    }, {});
    if (payload.isLogging) {
      yield put(actions.loginSuccess(payload));
    }
    yield put(actions.userInfoSuccess({ permissions }));
  } catch (ex: any) {
    console.log(ex);
    if (payload.isLogging) {
      yield put(actions.loginFailure(ex.message || ex.toString()));
    }
  } finally {
    if (yield cancelled()) {
      if (payload.isLogging) {
        yield put(actions.loginFailure('Cancelled'));
      }
    }
  }
}

function* logoutRequest() {
  // authService.logout();
}

function* auth() {
  yield takeLatest(ActionType.TOKEN_REQUEST, tokenRequestAsyncWorker);
  yield takeLatest(ActionType.LOGIN_REQUEST, loginRequestAsyncWorker);
  yield takeLatest(ActionType.USER_INFO_REQUEST, loadUserPermissionsWorker);
  yield takeLatest(ActionType.UPDATE_TOKEN, loadUserPermissionsWorker);
  yield takeLatest(ActionType.LOGOUT, logoutRequest);
}

export default auth;
