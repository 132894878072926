import { Action, ActionType } from '@store/interfaces/returns/dashboard-detail';

const initialState: any = { loading: true, items: [], total_count: 0, search_criteria: { page_size: 20, current_page: 1 } };

export default function returns(state = initialState, action: Action) {
  // const { payload } = action;
  switch (action.type) {
    case ActionType.GET_DASHBOARD_RETURNS_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_DASHBOARD_RETURNS_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: undefined,
        message: action?.message,
      };
    case ActionType.GET_DASHBOARD_RETURNS_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    case ActionType.CLEAR_STATE:
      return {
        loading: false,
        items: [],
        total_count: 0,
        search_criteria: { page_size: state?.search_criteria?.page_size || 10, current_page: 1 },
        message: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
