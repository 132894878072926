export const yesNoOptions = [
  { label: 'No', value: '0' },
  { label: 'Yes', value: '1' },
];
export const yesNoEmptyOptions = [{ label: ' ', value: '' }, ...yesNoOptions];

export const yesNoOpenOptions = [
  { label: 'Open', value: '0' },
  { label: 'No', value: '1' },
  { label: 'Yes', value: '2' },
];

export const yesNoOpenEmptyOptions = [{ label: ' ', value: '' }, ...yesNoOpenOptions];
