// eslint-disable-next-line
import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';
import { actions } from '@store/actions/aftership/orders';
import { Action, ActionType } from '@store/interfaces/aftership/orders';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getOrders({ payload }: Action) {
  try {
    const params: any = { ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.fetchAftershipOrders.bind(magentoService), params);
    yield put(actions.getOrdersSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getOrdersFailure(error?.message));
  }
}

function* ordersSaga() {
  yield takeLatest(ActionType.GET_ORDERS_REQUEST, getOrders);
}
export default ordersSaga;
