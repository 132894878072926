import { combineReducers } from 'redux';

import lateItemStats from './late-item-stats';
import latestOrder from './latest-order';
import share from './share';
import shipmentOverdueDelivery from './shipment-overdue-delivery';

const returnReducer = combineReducers({
  share,
  latestOrder,
  lateItemStats,
  shipmentOverdueDelivery,
  //
});

export default returnReducer;
