export enum ActionType {
  GET_RETURNS_DASHBOARD_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_REQUEST`,
  GET_RETURNS_DASHBOARD_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_SUCCESS`,
  GET_RETURNS_DASHBOARD_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_FAILURE`,

  GET_RETURNS_DASHBOARD_ORDERS_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_ORDERS_REQUEST`,
  GET_RETURNS_DASHBOARD_ORDERS_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_ORDERS_SUCCESS`,
  GET_RETURNS_DASHBOARD_ORDERS_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_ORDERS_FAILURE`,
  CLEAR_RETURNS_DASHBOARD_ORDERS_MESSAGE_ERROR = `returns/dashboard/CLEAR_RETURNS_DASHBOARD_ORDERS_MESSAGE_ERROR`,

  GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_REQUEST`,
  GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_SUCCESS`,
  GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_FAILURE`,
  CLEAR_RETURNS_DASHBOARD_SERVICE_WARRANTY_MESSAGE_ERROR = `returns/dashboard/CLEAR_RETURNS_DASHBOARD_SERVICE_WARRANTY_MESSAGE_ERROR`,

  GET_RETURNS_DASHBOARD_DISPATCHER_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_DISPATCHER_REQUEST`,
  GET_RETURNS_DASHBOARD_DISPATCHER_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_DISPATCHER_SUCCESS`,
  GET_RETURNS_DASHBOARD_DISPATCHER_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_DISPATCHER_FAILURE`,
  CLEAR_RETURNS_DASHBOARD_DISPATCHER_MESSAGE_ERROR = `returns/dashboard/CLEAR_RETURNS_DASHBOARD_DISPATCHER_MESSAGE_ERROR`,

  GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_REQUEST`,
  GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_SUCCESS`,
  GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_FAILURE`,
  CLEAR_RETURNS_DASHBOARD_FINANCE_TRANSACTION_MESSAGE_ERROR = `returns/dashboard/CLEAR_RETURNS_DASHBOARD_FINANCE_TRANSACTION_MESSAGE_ERROR`,

  GET_RETURNS_DASHBOARD_RETURN_JOURNEY_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_RETURN_JOURNEY_REQUEST`,
  GET_RETURNS_DASHBOARD_RETURN_JOURNEY_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_RETURN_JOURNEY_SUCCESS`,
  GET_RETURNS_DASHBOARD_RETURN_JOURNEY_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_RETURN_JOURNEY_FAILURE`,
  CLEAR_RETURNS_DASHBOARD_RETURN_JOURNEY_MESSAGE_ERROR = `returns/dashboard/CLEAR_RETURNS_DASHBOARD_RETURN_JOURNEY_MESSAGE_ERROR`,

  GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_REQUEST = `returns/dashboard/GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_REQUEST`,
  GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_SUCCESS = `returns/dashboard/GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_SUCCESS`,
  GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_FAILURE = `returns/dashboard/GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_FAILURE`,
  CLEAR_RETURNS_DASHBOARD_REPAIR_JOURNEY_MESSAGE_ERROR = `returns/dashboard/CLEAR_RETURNS_DASHBOARD_REPAIR_JOURNEY_MESSAGE_ERROR`,

  CLEAR_MESSAGE_ERROR = `returns/dashboard/CLEAR_MESSAGE_ERROR`,
  CLEAR_STATE = `returns/dashboard/CLEAR_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
//   | FSA<ActionType.TOKEN_REQUEST_SUCCESS>
//   | FSA<ActionType.TOKEN_REQUEST_FAILURE>
//   | FSA<ActionType.LOGIN_REQUEST>
//   | FSA<ActionType.LOGIN_SUCCESS>
//   | FSA<ActionType.LOGIN_FAILURE>
//   | FSA<ActionType.LOGOUT>
