import { Action, ActionType } from '@store/interfaces/share';

const setCurrentDispatcher = (payload: any): Action => {
  return { type: ActionType.SET_CURRENT_DISPATCHER, payload };
};

export const actions = {
  setCurrentDispatcher,
};

const dispatcher = {
  actions,
};

export default dispatcher;
