import { useEffect } from 'react';

/**
 * const ref = useRef();
 * // State for our modal
 * const [isModalOpen, setModalOpen] = useState(false);
 * // Call hook passing in the ref and a function to call on outside click
 * useOnClickOutside(ref, () => setModalOpen(false));
 * {isModalOpen ? (
 *       <div ref={ref}>
 *         👋 Hey, I'm a modal. Click anywhere outside of me to close.
 *       </div>
 *     ) : (
 *       <button onClick={() => setModalOpen(true)}>Open Modal</button>
 *     )}
 * @param ref
 * @param handler
 */
export function useOnClickOutside(ref: React.RefObject<HTMLElement>, handler: (event: any) => void) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || !event.target || ref.current.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
}
