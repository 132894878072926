import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '@components/Footer';

/**
 * The minimal app layout to be used on pages such Login/Signup,
 * Privacy Policy, Terms of Use, etc.
 */
export function BaseLayout() {
  return (
    <div className="wrapper mx-auto text-gray-900 font-normal grid grid-cols-[257px,1fr] grid-rows-[auto,1fr] min-h-screen base-layout" id="layout">
      <main className="flex flex-col justify-between pt-[42px] px-[23px] pb-[28px] min-h-screen overflow-x-clip">
        {/* <BaseToolbar />
      <Toolbar /> */}

        <React.Suspense>
          <Outlet />
        </React.Suspense>
        <Footer />
      </main>
    </div>
  );
}
