import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import createSagaMiddleware from 'redux-saga';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { REDUX_ENCRYPT_KEY } from '@constants';

import { reducers } from '@store/reducers';
import rootSaga from '@store/sagas';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['auth', 'return'],
  whitelist: ['share'],
};

const returnsPersistConfig = {
  key: 'returns',
  storage: storage,
  blacklist: ['loading', 'error'],
  whitelist: ['share'],
};

const reportPersistConfig = {
  key: 'report',
  storage: storage,
  blacklist: ['loading', 'error'],
  whitelist: ['share'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['loggingIn', 'error'],
  transforms: [
    encryptTransform({
      secretKey: REDUX_ENCRYPT_KEY,
      onError: (error) => {
        // Handle the error.
        process.env.NODE_ENV === 'development' && console.log(error);
      },
    }),
  ],
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
// const pReducer: any = persistReducer(persistConfig, rootReducer);
const { auth: authReducer, returns: returnsReducer, report: reportReducer, ...otherReducers } = reducers;
const rootReducer = combineReducers({
  auth: persistReducer<any>(authPersistConfig, authReducer),
  returns: persistReducer(returnsPersistConfig, returnsReducer),
  report: persistReducer(reportPersistConfig, reportReducer),
  ...otherReducers,
});

const pReducer: any = persistReducer<any>(rootPersistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middleware: any[] = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  /* eslint-disable @typescript-eslint/no-var-requires */
  const { logger } = require('redux-logger');
  middleware.push(logger);
}
const appReducer = (state: any, action: any) => {
  if (action.type == 'auth/logout') {
    return pReducer({}, action);
  }
  return pReducer(state, action);
};

const store = configureStore({
  reducer: appReducer,
  // disalbe thunk and add redux-saga, redux-logger middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
