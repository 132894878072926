import { Action, ActionType } from '@store/interfaces/shipments/pull-tracking-info';

const pullTrackingInfo = (id: string | number): Action => {
  return { type: ActionType.PULL_TRACKING_INFO_REQUEST, id };
};
const pullTrackingInfoSuccess = (payload: any): Action => {
  return { type: ActionType.PULL_TRACKING_INFO_REQUEST_SUCCESS, payload };
};
const pullTrackingInfoFailure = (error?: any): Action => {
  return { type: ActionType.PULL_TRACKING_INFO_REQUEST_FAILURE, error };
};
const clearPullTrackingInfoState = (): Action => {
  return { type: ActionType.CLEAR_PULL_TRACKING_INFO_REQUEST_STATE };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  pullTrackingInfo,
  pullTrackingInfoSuccess,
  pullTrackingInfoFailure,
  clearPullTrackingInfoState,
  clearMessageError,
};

const trackingInfo = {
  actions,
};

export default trackingInfo;
