import { useSelector } from 'react-redux';

import { ObjectInterface } from '@interfaces';

import { matchPermission } from '@helpers/permission';

export const useHasPermission = (id: string | string[], orQuery = false): boolean => {
  const permissions: ObjectInterface = useSelector((state: any) => state.auth?.user?.permissions || {});
  return matchPermission(id, permissions, orQuery);
};

export default useHasPermission;
