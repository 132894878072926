import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import i18next from 'i18next';

import { registerStore as apiAxiosRegisterStore } from '@services/api';
import { registerStore as authApiRegisterStore } from '@services/auth.service';

import auth from '@actions/auth';
import store, { persistor } from '@store';

import ForbiddenError from './errors/Forbidden';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import '@assets/style.scss';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  initImmediate: false,
});

apiAxiosRegisterStore(store);
authApiRegisterStore(
  store,
  (accessToken?: string) => {
    const state = store.getState();
    store.dispatch(auth.actions.updateToken({ accessToken, user: state?.auth?.user }));
  },
  () => {
    // store.dispatch(auth.actions.logout());
    throw new ForbiddenError();
  },
);
Modal.defaultStyles.overlay = {
  zIndex: 20,
};
Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
