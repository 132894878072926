import React from 'react';

import { PUBLIC_URL } from '@constants';

interface EmptyDataInterface {
  label?: string;
}

export const EmptyBox: React.FC<React.PropsWithChildren<EmptyDataInterface>> = ({ label = 'No Data', children }) => {
  return (
    <div className="bg-neutral-bg dark:bg-dark-neutral-bg py-[40px] px-[19px]">
      <div className="flex flex-col items-center">
        <img src={`${PUBLIC_URL}/static/assets/images/empty-box.png`} alt="" width={80} />
        <p className="font-semibold text-normal mt-4 mb-8 dark:text-gray-400">{label}</p>
        {children}
      </div>
    </div>
  );
};

export default EmptyBox;
