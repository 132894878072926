import { Action as AnyAction } from 'redux';

export type Meta = null | { [key: string]: any };
export interface FSA<Type extends string, Payload = null | { [key: string]: any }> extends AnyAction {
  type: Type;
  payload?: Payload;
  error?: boolean;
  meta?: Meta;
}

export interface UserInterface {
  id: string | number;
  email: string;
  name: string;
  roles: string[];
  [key: string]: any;
}

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  user: UserInterface | null;
}

export enum ActionType {
  TOKEN_REQUEST = 'auth/TOKEN_REQUEST',
  TOKEN_REQUEST_SUCCESS = 'auth/TOKEN_REQUEST_SUCCESS',
  UPDATE_TOKEN = 'auth/UPDATE_TOKEN',
  TOKEN_REQUEST_FAILURE = 'auth/TOKEN_REQUEST_FAILURE',
  USER_INFO_REQUEST = 'auth/USER_INFO_REQUEST',
  USER_INFO_SUCCESS = 'auth/USER_INFO_SUCCESS',
  LOGIN_REQUEST = 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
  LOGIN_FAILURE = 'auth/LOGIN_FAILURE',
  CLEAR_LOGIN_STATE = 'auth/CLEAR_LOGIN_STATE',
  LOGOUT = 'auth/LOGOUT',
}

export type Action =
  | FSA<ActionType.TOKEN_REQUEST>
  | FSA<ActionType.TOKEN_REQUEST_SUCCESS>
  | FSA<ActionType.TOKEN_REQUEST_FAILURE>
  | FSA<ActionType.UPDATE_TOKEN>
  | FSA<ActionType.LOGIN_REQUEST>
  | FSA<ActionType.LOGIN_SUCCESS>
  | FSA<ActionType.LOGIN_FAILURE>
  | FSA<ActionType.CLEAR_LOGIN_STATE>
  | FSA<ActionType.LOGOUT>
  | any;
