import { all } from 'redux-saga/effects';

import aftership from '@store/sagas/aftership';
import auth from '@store/sagas/auth';
import counter from '@store/sagas/counter';
import report from '@store/sagas/report';
import returns from '@store/sagas/returns';
import share from '@store/sagas/share';
import shipments from '@store/sagas/shipments';
import users from '@store/sagas/user';

function* rootSaga(): any {
  yield all([
    counter(),
    auth(),
    share(),
    aftership(),
    shipments(),
    returns(),
    report(),
    users(),
    // other watch
  ]);
}

export default rootSaga;
