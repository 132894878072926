import { Action, ActionType } from '@store/interfaces/report/shipment-overdue-delivery';

const getItems = (payload: any): Action => {
  return { type: ActionType.GET_ITEMS_REQUEST, payload };
};
const getItemsSuccess = (payload: any): Action => {
  return { type: ActionType.GET_ITEMS_REQUEST_SUCCESS, payload };
};
const getItemsFailure = (error?: any): Action => {
  return { type: ActionType.GET_ITEMS_REQUEST_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getItems,
  getItemsSuccess,
  getItemsFailure,

  clearMessageError,
};

const returns = {
  actions,
};

export default returns;
