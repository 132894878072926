import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { validateCache } from '@helpers';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/returns/share';
import { actions as shareActions } from '@store/actions/share';
import { ActionType } from '@store/interfaces/returns/share';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getReturnTypeRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getTypeReturnList.bind(magentoService));
    yield put(actions.getReturnTypeSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getReturnTypeFailure(error?.message));
  }
}

function* getItemStateRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnItemStateList.bind(magentoService));
    yield put(actions.getReturnItemStateSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getReturnItemStateFailure(error?.message));
  }
}

function* getReturnStatusRequest() {
  try {
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnStatusList.bind(magentoService));
    yield put(actions.getReturnStatusSuccess(dataResponse));
  } catch (error: any) {
    yield put(actions.getReturnStatusFailure(error?.message));
  }
}

const getShareMetaData = (state: any) => state?.returns?.share?.metadata || {};

function* getSharaDataRequest() {
  yield delay(500);
  const state: AnyResponseInterface = yield select(getShareMetaData);
  // TODO: check cache and don't refetch
  const ttl = 30 * 24 * 60 * 60 * 1000;
  const expiredTime = new Date().getTime() - ttl;
  yield put(shareActions.getShareData());
  if (!validateCache(state.itemState, expiredTime)) {
    yield put(actions.getReturnItemState());
  }
  if (!validateCache(state.status, expiredTime)) {
    yield put(actions.getReturnStatus());
  }
  if (!validateCache(state.type, expiredTime)) {
    yield put(actions.getReturnType());
  }
}

function* shareDataRequestSaga() {
  yield takeLatest(ActionType.GET_RETURN_SHARE_DATA_REQUEST, getSharaDataRequest);
  yield takeLatest(ActionType.GET_RETURN_TYPE_REQUEST, getReturnTypeRequest);
  yield takeLatest(ActionType.GET_RETURN_ITEM_STATE_REQUEST, getItemStateRequest);
  yield takeLatest(ActionType.GET_RETURN_STATUS_REQUEST, getReturnStatusRequest);
}
export default shareDataRequestSaga;
