import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Aside from '@components/Aside';
import Footer from '@components/Footer';
import Header from '@components/Header';
import PageTitle from '@components/PageTitle';

import Breadcrumb from './components/Breadcrumb';
import { PageTitleContext } from './context/PageTitleContext';
/**
 * The primary application layout.
 */
export function AppLayout() {
  const { pageTitle } = React.useContext(PageTitleContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  return (
    <div
      className={`wrapper mx-auto text-gray-900 font-normal grid grid-cols-[257px,1fr] grid-rows-[auto,1fr] min-h-screen ${
        isMobileMenuOpen ? 'active-menu' : ''
      }`}
      id="layout"
    >
      <Aside />
      <Header setIsMobileMenuOpen={setIsMobileMenuOpen} />
      <main className="flex flex-col pt-[42px] px-[23px] pb-[28px] overflow-hidden">
        <PageTitle title={pageTitle} />
        <Breadcrumb />
        <React.Suspense>
          <Outlet />
        </React.Suspense>

        <Footer />
        <section
          className={`fixed w-screen h-full cursor-pointer bg-gray-900 ${
            isMobileMenuOpen ? 'delay-300 transition-opacity opacity-25 translate-x-0' : 'transition-opacity delay-0 opacity-0 translate-x-full'
          }`}
          onClick={() => {
            setIsMobileMenuOpen(false);
          }}
        ></section>
      </main>
    </div>
  );
}
