import { combineReducers } from 'redux';

import order from './order';
import orders from './orders';

const aftershipReducer: any = combineReducers<any>({
  order,
  orders,
  // other
});

export default aftershipReducer;
