import { Action, ActionType } from '@store/interfaces/share';

const getTicketConfig = (): Action => {
  return { type: ActionType.GET_TICKET_CONFIG_REQUEST };
};
const getTicketConfigSuccess = (payload: any): Action => {
  return { type: ActionType.GET_TICKET_CONFIG_REQUEST_SUCCESS, payload };
};
const getTicketConfigFailure = (error?: any): Action => {
  return { type: ActionType.GET_TICKET_CONFIG_REQUEST_FAILURE, error };
};

const clearShareData = (): Action => {
  return { type: ActionType.CLEAR_SHARE_DATA };
};

export const actions = {
  getTicketConfig,
  getTicketConfigSuccess,
  getTicketConfigFailure,

  clearShareData,
};

const ticketConfig = {
  actions,
};

export default ticketConfig;
