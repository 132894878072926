import { Action, ActionType } from '@store/interfaces/shipments/dashboard-detail';

const getShipmentDashboardDetailItems = (payload: any): Action => {
  return { type: ActionType.GET_DASHBOARD_SHIPMENT_DETAIL, payload };
};
const getShipmentDashboardDetailItemsSuccess = (payload: any): Action => {
  return { type: ActionType.GET_DASHBOARD_SHIPMENT_DETAIL_SUCCESS, payload };
};
const getShipmentDashboardDetailItemsFailure = (error?: any): Action => {
  return { type: ActionType.GET_DASHBOARD_SHIPMENT_DETAIL_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

const clearState = (): Action => {
  return { type: ActionType.CLEAR_STATE };
};

export const actions = {
  getShipmentDashboardDetailItems,
  getShipmentDashboardDetailItemsSuccess,
  getShipmentDashboardDetailItemsFailure,

  clearMessageError,
  clearState,
};

const dashboardShipmentDetailItems = {
  actions,
};

export default dashboardShipmentDetailItems;
