import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { PUBLIC_URL } from '@constants';
import { categoryNav, menus } from '@constants/menu';

import { Icon } from '@components/base';
import ListGroup from '@components/ListGroup/ListGroup';
import Nav from '@components/Nav';
import ThemeSwitch from '@components/ThemeSwitch';

const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
  let timeout: number | undefined | NodeJS.Timeout = 0;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result;
  };
};

function Aside() {
  const [miniNavigation, setMiniNavigation] = useState<boolean>(localStorage.getItem('miniNavigation') == '1');

  const toggleMiniNavigation = useCallback(() => {
    setMiniNavigation(!miniNavigation);
    localStorage.setItem('miniNavigation', !miniNavigation ? '1' : '0');
  }, [miniNavigation, setMiniNavigation]);

  useEffect(() => {
    const layout = document.querySelector('#layout');
    if (layout) {
      if (miniNavigation && !layout.classList.contains('minimize')) {
        layout.classList.add('minimize');
        layout.classList.remove('grid-cols-[257px,1fr]');
      } else {
        setMiniNavigation(false);
        layout.classList.add('grid-cols-[257px,1fr]');
        layout.classList.remove('minimize');
      }
    }
  }, [miniNavigation]);

  useEffect(() => {
    const handleResizeListener = () => {
      const layout = document.querySelector('#layout');
      if (!layout) {
        return;
      }
      if (window.innerWidth >= 768) {
        if (layout && !layout.classList.contains('minimize') && miniNavigation) {
          const btn = document.querySelector('#sidebar-btn');
          btn && btn.classList.add('reverse');
          layout.classList.add('minimize');
          layout.classList.remove('grid-cols-[257px,1fr]');
        }
        return;
      }
      if (layout && layout.classList.contains('minimize')) {
        const btn = document.querySelector('#sidebar-btn');
        btn && btn.classList.remove('reverse');
        layout.classList.remove('minimize');
        layout.classList.remove('grid-cols-[257px,1fr]');
      }
    };
    function delegateItem(selector: string, handler: (e: any) => void) {
      return function (this: any, event: any) { // eslint-disable-line
        let el: any = event.target;
        do {
          if (el && el.matches && el.matches(selector)) {
            handler.call(el, event);
          }
        } while ((el = el.parentNode) && el !== this); // eslint-disable-line
      };
    }
    let currentActiveMenu: any = null;
    const handleClickMenuItem = debounce(delegateItem('.sidemenu-item', function(this: any, event: any) { // eslint-disable-line
        const activeMenu = document.querySelectorAll('aside .sidemenu-item.active');
        activeMenu.forEach((m) => {
          m.classList.remove('active');
        });
        this.classList.add('active');
        currentActiveMenu = this; // eslint-disable-line
      }),
      50,
    );
    const handleClickOutside = (event: any) => {
      if (!currentActiveMenu) {
        return;
      }
      const isClickInside = currentActiveMenu.contains(event.target);

      if (!isClickInside) {
        const activeMenuInput: any = document.querySelector('aside .sidemenu-item.active > input[type=checkbox]');
        activeMenuInput && (activeMenuInput.checked = false);
      }
    };
    const asideElm = document.querySelector('aside');
    asideElm && asideElm.addEventListener('click', handleClickMenuItem);
    document.addEventListener('click', handleClickOutside);
    window.addEventListener('resize', handleResizeListener);
    handleResizeListener();
    return () => {
      window.removeEventListener('resize', handleResizeListener);
      asideElm && asideElm.removeEventListener('click', handleClickMenuItem);
    };
  }, []);

  return (
    <aside className="bg-white row-span-2 border-r border-neutral relative flex flex-col justify-between p-[10px] dark:bg-dark-neutral-bg dark:border-dark-neutral-border">
      <div
        className={`absolute p-2 top-[28px] border-neutral right-0 border bg-white rounded-full cursor-pointer duration-300 translate-x-1/2 hover:opacity-75 dark:bg-dark-neutral-bg dark:border-dark-neutral-border
         ${miniNavigation ? 'reverse' : ''}`}
        onClick={toggleMiniNavigation}
        id="sidebar-btn"
      >
        <Icon name="arrow-left" alt="left chevron icon" />
      </div>
      <div>
        <Link className="flex item-center" to={'/'}>
          <img className="logo-maximize dark:invert ml-auto mr-auto mt-0 md:mt-[25px]" src={`${PUBLIC_URL}/logo.svg`} alt="Glamira logo" />
          <img className="logo-minimize dark:invert m-auto" src={`${PUBLIC_URL}/logo-mini.svg`} alt="Glamira logo" />
        </Link>
        <div className="mt-1 border-top-1 md:pt-[106px] lg:pt-[35px] pb-[18px]">
          {menus.map((item, idx) => {
            return <Nav key={idx} {...item} autoActive={!miniNavigation} />;
          })}
        </div>
        {categoryNav.length > 0 && (
          <div className="category-list">
            <ListGroup items={categoryNav} label={'Categories'} />
          </div>
        )}
        <div className="w-full bg-neutral h-[1px] mb-[35px] dark:bg-dark-neutral-border"></div>
      </div>
      <div className="sticky bottom-0 bg-white dark:bg-dark-neutral-bg pt-[10px] pb-[10px]">
        <ThemeSwitch />
      </div>
    </aside>
  );
}

export default Aside;
