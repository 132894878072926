import { Action, ActionType } from '@store/interfaces/shipments/dashboard';

const initialState = { preShipment: { loading: false, data: {} }, dispatcher: { loading: false, data: {} } };

export default function shipments(state = initialState, action: Action) {
  // const { payload } = action;
  switch (action.type) {
    case ActionType.GET_PRE_SHIPMENT_DASHBOARD_REQUEST:
      return {
        ...state,
        preShipment: {
          ...state.preShipment,
          loading: true,
          message: undefined,
        },
      };
    case ActionType.GET_PRE_SHIPMENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        preShipment: {
          ...state.preShipment,
          data: action.payload,
          loading: false,
          message: action?.message,
        },
      };
    case ActionType.GET_PRE_SHIPMENT_DASHBOARD_FAILURE:
      return {
        ...state,
        preShipment: {
          ...state.preShipment,
          data: {},
          loading: false,
          message: action?.error,
        },
      };
    case ActionType.CLEAR_MESSAGE_PRE_SHIPMENT_DASHBOARD_ERROR:
      return {
        ...state,
        preShipment: {
          ...state.preShipment,
          message: undefined,
        },
      };
    case ActionType.CLEAR_PRE_SHIPMENT_DASHBOARD_STATE:
      return {
        ...state,
        preShipment: {
          loading: false,
          data: {},
          message: undefined,
        },
      };
    case ActionType.GET_DISPATCHER_DASHBOARD_REQUEST:
      return {
        ...state,
        dispatcher: {
          ...state.dispatcher,
          loading: true,
        },
      };
    case ActionType.GET_DISPATCHER_DASHBOARD_SUCCESS:
      return {
        ...state,
        dispatcher: {
          ...state.dispatcher,
          data: action.payload,
          loading: false,
          message: action?.message,
        },
      };
    case ActionType.GET_DISPATCHER_DASHBOARD_FAILURE:
      return {
        ...state,
        dispatcher: {
          ...state.dispatcher,
          data: {},
          loading: false,
          message: action?.error,
        },
      };
    case ActionType.CLEAR_MESSAGE_DISPATCHER_DASHBOARD_ERROR:
      return {
        ...state,
        dispatcher: {
          ...state.dispatcher,
          loading: false,
          message: undefined,
        },
      };
    case ActionType.CLEAR_DISPATCHER_DASHBOARD_STATE:
      return {
        ...state,
        dispatcher: {
          loading: false,
          data: {},
          message: undefined,
        },
      };
    case ActionType.CLEAR_STATE:
      return { preShipment: { loading: false, data: {} }, dispatcher: { loading: false, data: {} } };
    default:
      return state;
  }
}
