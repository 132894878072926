import { SHARE_DATA_VERSION } from '@constants';

import { Action, ActionType } from '@store/interfaces/returns/share';

const initialState: any = { type: [], itemState: [], status: [], countries: [], dispatchers: [], metadata: {} };

export default function returns(state = initialState, action: Action) {
  const { payload } = action;
  switch (action.type) {
    case ActionType.GET_RETURN_TYPE_SUCCESS:
      return {
        ...state,
        type: payload,
        metadata: {
          ...state.metadata,
          type: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_RETURN_STATUS_SUCCESS:
      return {
        ...state,
        status: payload,
        metadata: {
          ...state.metadata,
          status: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.GET_RETURN_ITEM_STATE_SUCCESS:
      return {
        ...state,
        itemState: payload,
        metadata: {
          ...state.metadata,
          itemState: {
            updatedAt: new Date().getTime(),
            version: SHARE_DATA_VERSION,
          },
        },
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    default:
      return state;
  }
}
