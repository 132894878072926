import { call, put, takeLatest } from 'redux-saga/effects';

import { ObjectInterface } from '@interfaces';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/shipments/dashboard-detail';
import { Action, ActionType } from '@store/interfaces/shipments/dashboard-detail';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getShipmentPackagesRequest({ payload }: Action) {
  try {
    const { type = '', detail_field = '', detail_type = '', ...params } = { ...(payload || { type: '' }) };
    const query: ObjectInterface = { detail_type, ...params };
    let dataResponse: AnyResponseInterface | null = null;
    if (type === 'dispatcher') {
      if (detail_field) {
        dataResponse = yield call(magentoService.getShipmentDashboardDispatcherItemsDetail.bind(magentoService), query);
      } else {
        dataResponse = yield call(magentoService.getShipmentDashboardDispatcherDetails.bind(magentoService), query);
      }
    } else if (type === 'order-pre-shipment') {
      if (detail_field) {
        query.is_overdue = 1;
      }
      if (detail_type === 'canceled') {
        dataResponse = yield call(magentoService.getShipmentDashboardOrderDetails.bind(magentoService), query);
      } else {
        dataResponse = yield call(magentoService.getShipmentDashboardProductionDetails.bind(magentoService), query);
      }
    }
    if (!dataResponse) {
      throw new Error('Invalid type!');
    }
    yield put(actions.getShipmentDashboardDetailItemsSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardOrdersError());
  } catch (error: any) {
    yield put(actions.getShipmentDashboardDetailItemsFailure(error?.message));
  }
}

function* shipmentDashboardDetailSaga() {
  yield takeLatest(ActionType.GET_DASHBOARD_SHIPMENT_DETAIL, getShipmentPackagesRequest);
}

export default shipmentDashboardDetailSaga;
