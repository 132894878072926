export enum ActionType {
  PULL_TRACKING_INFO_REQUEST = `shipments/package-order/PULL_TRACKING_INFO_REQUEST`,
  PULL_TRACKING_INFO_REQUEST_SUCCESS = `shipments/package-order/PULL_TRACKING_INFO_REQUEST_SUCCESS`,
  PULL_TRACKING_INFO_REQUEST_FAILURE = `shipments/package-order/PULL_TRACKING_INFO_REQUEST_FAILURE`,

  CLEAR_MESSAGE_ERROR = `shipments/packages/CLEAR_MESSAGE_ERROR`,
  CLEAR_PULL_TRACKING_INFO_REQUEST_STATE = `shipments/packages/CLEAR_PULL_TRACKING_INFO_REQUEST_STATE`,
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
