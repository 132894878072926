import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ObjectInterface } from '@interfaces';

import { matchPermission } from '@helpers/permission';

function getFirstMenuCanAccess(item: any, permissions: ObjectInterface = {}): any | null {
  if (Array.isArray(item)) {
    const len = item.length;
    for (let i = 0; i < len; i += 1) {
      const matchItem = getFirstMenuCanAccess(item[i], permissions);
      if (matchItem) {
        return matchItem;
      }
    }
    return null;
  }
  const permission: string = item.permission || false;
  if (permission && !matchPermission(permission, permissions)) {
    return null;
  }
  if (item.items) {
    return getFirstMenuCanAccess(item.items, permissions);
  }
  if (!item.to) {
    return null;
  }
  return item;
}
export const useFirstMenuCanAccess = (menuItems: any): ({ to?: string } & any) | null => {
  const permissions: ObjectInterface = useSelector((state: any) => state.auth?.user?.permissions || {});
  const [item, setItem] = useState<({ to?: string } & any) | null>(null);
  useEffect(() => {
    setItem(getFirstMenuCanAccess(menuItems, permissions));
  }, [permissions]);
  return item;
};

export default useFirstMenuCanAccess;
