import { Action, ActionType } from '@store/interfaces/returns/dashboard';

const initialState: any = { loading: true, orders: {} };

export default function returns(state = initialState, action: Action) {
  // const { payload } = action;
  switch (action.type) {
    case ActionType.GET_RETURNS_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_RETURNS_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        message: action?.message,
      };
    case ActionType.GET_RETURNS_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    /* ==== ORDERS ==== */
    case ActionType.GET_RETURNS_DASHBOARD_ORDERS_REQUEST:
      return {
        ...state,
        orders: {
          ...(state.orders || {}),
          loading: true,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_ORDERS_FAILURE:
      return {
        ...state,
        orders: {
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_RETURNS_DASHBOARD_ORDERS_MESSAGE_ERROR:
      return {
        ...state,
        orders: {
          ...(state.orders || {}),
          message: undefined,
          error: undefined,
        },
      };

    /* ==== SERVICE WARRANTY ==== */
    case ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_REQUEST:
      return {
        ...state,
        serviceWarranty: {
          ...(state.orders || {}),
          loading: true,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_SUCCESS:
      return {
        ...state,
        serviceWarranty: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_FAILURE:
      return {
        ...state,
        serviceWarranty: {
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_RETURNS_DASHBOARD_SERVICE_WARRANTY_MESSAGE_ERROR:
      return {
        ...state,
        serviceWarranty: {
          ...(state.orders || {}),
          message: undefined,
          error: undefined,
        },
      };

    /* ==== DISPATCHER ==== */
    case ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_REQUEST:
      return {
        ...state,
        dispatcher: {
          ...(state.orders || {}),
          loading: true,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_SUCCESS:
      return {
        ...state,
        dispatcher: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_FAILURE:
      return {
        ...state,
        dispatcher: {
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_RETURNS_DASHBOARD_DISPATCHER_MESSAGE_ERROR:
      return {
        ...state,
        dispatcher: {
          ...(state.orders || {}),
          message: undefined,
          error: undefined,
        },
      };

    /* ==== _FINANCE_TRANSACTION_ ==== */
    case ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_REQUEST:
      return {
        ...state,
        financeTransaction: {
          ...(state.orders || {}),
          loading: true,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_SUCCESS:
      return {
        ...state,
        financeTransaction: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_FAILURE:
      return {
        ...state,
        financeTransaction: {
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_RETURNS_DASHBOARD_FINANCE_TRANSACTION_MESSAGE_ERROR:
      return {
        ...state,
        financeTransaction: {
          ...(state.orders || {}),
          message: undefined,
          error: undefined,
        },
      };

    /* ==== _RETURN_JOURNEY_ ==== */
    case ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_REQUEST:
      return {
        ...state,
        returnJourney: {
          ...(state.orders || {}),
          loading: true,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_SUCCESS:
      return {
        ...state,
        returnJourney: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_FAILURE:
      return {
        ...state,
        returnJourney: {
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_RETURNS_DASHBOARD_RETURN_JOURNEY_MESSAGE_ERROR:
      return {
        ...state,
        returnJourney: {
          ...(state.orders || {}),
          message: undefined,
          error: undefined,
        },
      };

    /* ==== _REPAIR_JOURNEY_ ==== */
    case ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_REQUEST:
      return {
        ...state,
        repairJourney: {
          ...(state.orders || {}),
          loading: true,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_SUCCESS:
      return {
        ...state,
        repairJourney: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };
    case ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_FAILURE:
      return {
        ...state,
        repairJourney: {
          loading: false,
          error: action?.error,
        },
      };
    case ActionType.CLEAR_RETURNS_DASHBOARD_REPAIR_JOURNEY_MESSAGE_ERROR:
      return {
        ...state,
        repairJourney: {
          ...(state.orders || {}),
          message: undefined,
          error: undefined,
        },
      };

    case ActionType.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
