import React from 'react';

export interface PageTitleIterface {
  title: string;
}

function PageTitle(props: PageTitleIterface) {
  const { title } = props;
  if (!title) {
    return <></>;
  }
  return <h2 className="capitalize text-gray-1100 font-bold text-[28px] leading-[35px] dark:text-gray-dark-1100 mb-[13px]">{title}</h2>;
}
export default PageTitle;
