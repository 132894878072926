import { Action, ActionType } from '@store/interfaces/shipments/shipout-waiting';

const getList = (payload: any): Action => {
  return { type: ActionType.GET_SHIPOUT_WAITING_REQUEST, payload };
};
const getListSuccess = (payload: any): Action => {
  return { type: ActionType.GET_SHIPOUT_WAITING_REQUEST_SUCCESS, payload };
};
const getListFailure = (error?: any): Action => {
  return { type: ActionType.GET_SHIPOUT_WAITING_REQUEST_FAILURE, error };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getList,
  getListSuccess,
  getListFailure,
  clearMessageError,
};

const shipoutWaiting = {
  actions,
};

export default shipoutWaiting;
