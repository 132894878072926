import { call, put, takeLatest } from 'redux-saga/effects';

import magentoService from '@services/magento.service';

import { actions } from '@store/actions/returns/dashboard';
import { Action, ActionType } from '@store/interfaces/returns/dashboard';

interface AnyResponseInterface {
  [key: string]: any;
}

function* getOrdersStats({ payload }: Action) {
  try {
    const params: any = { type: 'after_sales_processing', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnOrderStats.bind(magentoService), params);
    yield put(actions.getReturnsDashboardOrdersSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardOrdersError());
  } catch (error: any) {
    yield put(actions.getReturnsDashboardOrdersFailure(error?.message));
  }
}

function* getServiceWarrantyStats({ payload }: Action) {
  try {
    const params: any = { type: 'service_warranty', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnServiceWarrantyStats.bind(magentoService), params);

    yield put(actions.getReturnsDashboardServiceWarrantySuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardServiceWarrantyError());
  } catch (error: any) {
    yield put(actions.getReturnsDashboardServiceWarrantyFailure(error?.message));
  }
}

function* getDispatcherStats({ payload }: Action) {
  try {
    const params: any = { type: 'dispatcher', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnDispatcherStats.bind(magentoService), params);

    yield put(actions.getReturnsDashboardDispatcherSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardDispatcherError());
  } catch (error: any) {
    yield put(actions.getReturnsDashboardDispatcherFailure(error?.message));
  }
}

function* getFinanceTransactionStats({ payload }: Action) {
  try {
    const params: any = { type: 'financeTransaction', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnFinanceTransactionStats.bind(magentoService), params);

    yield put(actions.getReturnsDashboardFinanceTransactionSuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardFinanceTransactionError());
  } catch (error: any) {
    yield put(actions.getReturnsDashboardFinanceTransactionFailure(error?.message));
  }
}

function* getReturnJourneyStats({ payload }: Action) {
  try {
    const params: any = { type: 'returnJourney', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getReturnJourneyStats.bind(magentoService), params);

    yield put(actions.getReturnsDashboardReturnJourneySuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardReturnJourneyError());
  } catch (error: any) {
    yield put(actions.getReturnsDashboardReturnJourneyFailure(error?.message));
  }
}

function* getRepairJourneyStats({ payload }: Action) {
  try {
    const params: any = { type: 'returnJourney', ...(payload || {}) };
    const dataResponse: AnyResponseInterface = yield call(magentoService.getRepairJourneyStats.bind(magentoService), params);

    yield put(actions.getReturnsDashboardRepairJourneySuccess(dataResponse));
    //yield put(actions.clearShipmentDashboardRepairJourneyError());
  } catch (error: any) {
    yield put(actions.getReturnsDashboardRepairJourneyFailure(error?.message));
  }
}

function* returnsSaga() {
  yield takeLatest(ActionType.GET_RETURNS_DASHBOARD_ORDERS_REQUEST, getOrdersStats);
  yield takeLatest(ActionType.GET_RETURNS_DASHBOARD_SERVICE_WARRANTY_REQUEST, getServiceWarrantyStats);
  yield takeLatest(ActionType.GET_RETURNS_DASHBOARD_DISPATCHER_REQUEST, getDispatcherStats);
  yield takeLatest(ActionType.GET_RETURNS_DASHBOARD_FINANCE_TRANSACTION_REQUEST, getFinanceTransactionStats);
  yield takeLatest(ActionType.GET_RETURNS_DASHBOARD_RETURN_JOURNEY_REQUEST, getReturnJourneyStats);
  yield takeLatest(ActionType.GET_RETURNS_DASHBOARD_REPAIR_JOURNEY_REQUEST, getRepairJourneyStats);
}
export default returnsSaga;
