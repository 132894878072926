import { Action, ActionType } from '@store/interfaces/share';

const getDispatchers = (forceCache = true): Action => {
  return { type: ActionType.GET_DISPATCHERS_REQUEST, forceCache };
};

const getDispatchersCached = (): Action => {
  return { type: ActionType.GET_DISPATCHERS_CACHED };
};
const getDispatchersSuccess = (payload: any): Action => {
  return { type: ActionType.GET_DISPATCHERS_SUCCESS, payload };
};
const getDispatchersFailure = (error?: any): Action => {
  return { type: ActionType.GET_DISPATCHERS_FAILURE, error };
};

export const actions = {
  getDispatchers,
  getDispatchersCached,
  getDispatchersSuccess,
  getDispatchersFailure,
};

const dispatcher = {
  actions,
};

export default dispatcher;
