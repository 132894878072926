import { combineReducers } from 'redux';

import dashboard from './dashboard';
import dashboardDetail from './dashboard-detail';
import requestItem from './request-item';
import share from './share';

const returnReducer = combineReducers({
  share,
  dashboard,
  dashboardDetail,
  requestItem,
});

export default returnReducer;
